import { Link, useNavigate, useParams } from "react-router-dom"
import RouteString from "../../Lib/RouteString"
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFoodTypes } from '../../Store/FoodType/slice'
import { toast } from "react-hot-toast";
import axios from "axios";
import { APIHost } from '../../Lib/Constant'
import { AuthContext } from '../../Context/ContextConfig'
import React from "react";

const FoodAddPage = () => {
    const authContext = useContext(AuthContext)
    const navigate = useNavigate()
    // Data from DB
    const [allFoodTypes, setAllFoodTypes] = useState([])

    //Data to DB
    const [foodTypes, setFoodTypes] = useState([])
    const [foodPrices, setFoodPrices] = useState([{ id: 0, price: 0, ration: 0, variant: '' }])
    const [foodName, setFoodName] = useState('')
    const [foodDescription, setFoodDescription] = useState('')
    const [images, setImages] = useState([]);
    const [imageBlobUrls, setImageBlobUrls] = useState([]);

    const [updating, setUpdating] = useState(false)


    const handleImageUpload = async (event) => {
        const files = event.target.files;
        const newimages = [...images];
        const newImageBlobUrls = [...imageBlobUrls];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            if (file.type.startsWith('image/')) {
                reader.onloadend = () => {
                    newimages.push(file);
                    const blobUrl = URL.createObjectURL(file);
                    newImageBlobUrls.push(blobUrl);
                    setImages(newimages);
                    setImageBlobUrls(newImageBlobUrls);
                };

                reader.readAsDataURL(file);
            } else
                toast.error('Vui lòng chọn tập tin hình ảnh!')
        }
    };


    const dispatch = useDispatch()
    const { getAllFoodTypesResult } = useSelector(state => state.foodType)
    useEffect(() => {
        dispatch(getAllFoodTypes())
        document.title = 'Thêm món ăn mới - Quán ăn Cường Loan'
    }, [])

    useEffect(() => {
        if (getAllFoodTypesResult !== undefined) {
            setAllFoodTypes(getAllFoodTypesResult.data)
        }
    }, [getAllFoodTypesResult])
    const onChangeRation = (index, value) => {
        const newFoodPrices = [...foodPrices];
        newFoodPrices[index].ration = value;
        setFoodPrices(newFoodPrices);
    }
    const onChangePrice = (index, value) => {
        const newFoodPrices = [...foodPrices];
        newFoodPrices[index].price = value;
        setFoodPrices(newFoodPrices);
    }
    const onChangeVariant = (index, value) => {
        const newFoodPrices = [...foodPrices];
        newFoodPrices[index].variant = value;
        setFoodPrices(newFoodPrices);
    }
    const addFoodHandle = () => {
        toast.remove()
        if (foodName === '')
            toast.error('Vui lòng nhập tên món ăn')
        if (foodDescription === '')
            toast.error('Vui lòng nhập mô tả về món ăn')
        if (foodTypes.length === 0)
            toast.error('Vui lòng chọn ít nhất một loại món ăn')
        if (images.length === 0)
            toast.error('Vui lòng chọn ít nhất một ảnh cho món ăn')
        if (foodName !== '' && foodDescription !== '' && foodTypes.length !== 0 && images.length !== 0) {
            const formData = new FormData();
            formData.append('foodname', foodName);
            formData.append('fooddescription', foodDescription)
            foodTypes.forEach((type, index) => {
                formData.append(`foodtypes[${index}]`, type)
            });
            foodPrices.forEach((price, index) => {
                formData.append(`variants[${index}]`, JSON.stringify(price))
            })
            images.length > 0 &&
                images.forEach((image, index) => {
                    formData.append(`images[${index}]`, image)
                })
            try {
                setUpdating(true)
                axios({
                    url: `${APIHost}/add-food`,
                    method: 'post',
                    headers: { 'Authorization': `Bearer ${authContext.token}` },
                    data: formData
                }).then((result) => {
                    toast.remove()
                    setUpdating(false)
                    if (result.data.success) {
                        navigate(`/foods/${result.data.food.Slug}`)
                        toast.success(result.data.message)
                        setImages([])
                        setImageBlobUrls([])
                    }
                    else
                        toast.error(result.data.message.foodname[0])
                })
                // setFoodTypeImageNew(null)
            } catch (error) {
                toast.error(`Vui lòng thử lại sau`)
            }
        }
    }
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.foodAddRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to={RouteString.foodRoute.route} className="btn btn-outline-success" type="button">{RouteString.foodRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Thêm món ăn</h5>
                            </div>
                            <div className="form theme-form">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="foodAddName">Tên món ăn</label>
                                                <input className="form-control"
                                                    value={foodName}
                                                    onChange={(e) => setFoodName(e.target.value)}
                                                    id="foodAddName" type="text"
                                                    placeholder="Nhập tên mới cho món ăn" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="foodAddDescription">Mô tả món ăn</label>
                                                <input className="form-control"
                                                    value={foodDescription}
                                                    onChange={(e) => setFoodDescription(e.target.value)}
                                                    id="foodAddDescription" type="text"
                                                    placeholder="Nhập mô tả mới cho món ăn" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="foodAddType">Loại món ăn</label>
                                                {
                                                    allFoodTypes.map(e => (
                                                        <div key={Math.random()} className="form-check checkbox checkbox-primary mb-0">
                                                            <input className="form-check-input" id={e.Slug} value={e.ID} type="checkbox"
                                                                defaultChecked={foodTypes.indexOf(e.ID) >= 0 ? true : false}
                                                                onChange={(t) => (t.target.checked ? setFoodTypes(foodTypes => [...foodTypes, e.ID]) :
                                                                    setFoodTypes(foodTypes => foodTypes.filter(f => f !== e.ID))
                                                                )}
                                                            />
                                                            <label className="form-check-label" htmlFor={e.Slug}>{e.Name}</label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="foodAddVariant">Mức giá và khẩu phần</label>
                                                {
                                                    foodPrices.map((e, i) => (
                                                        <div className="row theme-form mt-3" key={i}>
                                                            <div className="col-xxl-3 mb-3 d-flex align-items-center">
                                                                <label className="col-form-label pe-2" >Khẩu phần:</label>
                                                                <input className="form-control"
                                                                    key={i}
                                                                    value={e.ration}
                                                                    onChange={(t) => onChangeRation(i, t.target.value)}
                                                                    type="text"
                                                                    placeholder="Khẩu phần" />
                                                            </div>
                                                            <div className="col-xxl-3 mb-3 d-flex align-items-center">
                                                                <label className="col-form-label pe-2">Mức giá:</label>
                                                                <input className="form-control"
                                                                    key={i}
                                                                    value={e.price}
                                                                    onChange={(t) => onChangePrice(i, t.target.value)}
                                                                    type="text"
                                                                    placeholder="Mức giá" />
                                                            </div>
                                                            <div className="col-xxl-3 mb-3 d-flex align-items-center">
                                                                <label className="col-form-label pe-2" >Đặt tên cho mức giá:</label>
                                                                <input className="form-control"
                                                                    key={i}
                                                                    value={e.variant}
                                                                    onChange={(t) => onChangeVariant(i, t.target.value)}
                                                                    type="text"
                                                                    placeholder="Đặt tên cho mức giá" />
                                                            </div>
                                                            <div className="col-xxl-3 mb-3 d-flex align-items-center btn-showcase">
                                                                <button
                                                                    onClick={() => {
                                                                        if (e.id !== -1)
                                                                            setFoodPrices(foodPrices => foodPrices.filter((item, idx) => idx !== i))
                                                                    }
                                                                    }
                                                                    className="btn btn-danger" disabled={foodPrices.length === 1}>Xóa</button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <button className="btn btn-success"
                                                    onClick={() =>
                                                        setFoodPrices(foodPrices => [...foodPrices,
                                                        { id: 0, price: 0, ration: 0, variant: '' }
                                                        ])
                                                    }
                                                    disabled={foodPrices.some(item => item.price === 0 || item.ration === 0 || item.variant === '')}
                                                >Thêm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="foodAddImage">Chọn ảnh mới cho món ăn</label>
                                                <input className="form-control" type="file" multiple onChange={handleImageUpload} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {images.length > 0 &&
                                            images.map((image, index) => (
                                                <div className="col-md-6 col-xxl-3 box-col-6" key={Math.random()}>
                                                    <div className="card">
                                                        <div className="blog-box blog-grid text-center">
                                                            <img className="img-fluid top-radius-blog" src={imageBlobUrls[index]} alt="" />
                                                            <button
                                                                onClick={() => {
                                                                    setImages(images => images.filter((item, idx) => idx !== index))
                                                                    setImageBlobUrls(imageBlobUrls => imageBlobUrls.filter((item, idx) => idx !== index))
                                                                }}
                                                                className="btn btn-outline-danger btn-xs"
                                                                disabled={images.length === 1}
                                                                type="button">Xóa ảnh này</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <button className="btn btn-primary"
                                        onClick={() => addFoodHandle()}
                                        disabled={foodPrices.some(item => item.price === 0 || item.ration === 0 || item.variant === '') || updating}

                                        type="submit">{
                                            updating ?
                                                'Đang thêm món'
                                                :
                                                'Thêm món ăn'
                                        }</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default FoodAddPage