const RouteString = {
    // Auth route
    loginRoute: {
        route: '/login',
        txt: 'Đăng nhập'
    },
    homeRoute: {
        route: '/',
        txt: 'Trang chủ'
    },
    adminRoute: {
        route: '/admins',
        txt: 'Quản trị viên'
    },
    foodTypeDetailRoute: {
        route: '/food-types/:foodTypeSlug',
        txt: 'Chi tiết loại món ăn'
    },
    foodTypeRoute: {
        route: '/food-types',
        txt: 'Loại món ăn'
    },
    foodUpdateRoute: {
        route: '/update-food/:foodSlug',
        txt: 'Cập nhật món ăn'
    },
    foodDetailRoute: {
        route: '/foods/:foodSlug',
        txt: 'Chi tiết món ăn'
    },
    foodRoute: {
        route: '/foods',
        txt: 'Món ăn'
    },
    foodAddRoute: {
        route: '/add-food',
        txt: 'Thêm món ăn'
    },
    ordersRoute: {
        route: '/orders',
        txt: 'Đơn đặt món ăn'
    },
    orderDetailRoute: {
        route: '/order/:orderID',
        txt: 'Chi tiết đơn đặt món ăn'
    },
    partyPrepareRoute: {
        route: '/party-preparing/:page',
        txt: 'Tiệc sắp đến'
    },
    partyDoneRoute: {
        route: '/done-party/:page',
        txt: 'Tiệc đã hoàn thành'
    },
    partyCreateRoute: {
        route: '/party-create',
        txt: 'Tạo đơn đặt tiệc'
    },
    partyUpdateRoute: {
        route: '/party-update/:partyID',
        txt: 'Cập nhật đơn đặt tiệc'
    },
    partyViewRoute: {
        route: '/party/:partyID',
        txt: 'Xem đơn đặt tiệc'
    },
    menuRoute: {
        route: '/menus',
        txt: 'Menu'
    },
    menuCreateRoute: {
        route: '/create-menu',
        txt: 'Tạo menu'
    },
    menuUpdateRoute: {
        route: '/update-menu/:menuID',
        txt: 'Cập nhật menu'
    },
}

export default RouteString