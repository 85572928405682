import { useDispatch, useSelector } from "react-redux"
import RouteString from "../../Lib/RouteString"
import { useEffect, useState } from "react"
import { getAllFoodTypes } from '../../Store/FoodType/slice'
import FoodTypeList from "./FoodTypeList"
import FoodTypeAddModal from "./FoodTypeAddModal"
import { Link } from "react-router-dom"
import React from "react"
const FoodTypePage = () => {
    const dispatch = useDispatch()
    const { getAllFoodTypesResult } = useSelector(state => state.foodType)
    const [foodTypeList, setFoodTypeList] = useState([])
    useEffect(() => {
        dispatch(getAllFoodTypes())
        document.title='Loại món ăn - Quán ăn Cường Loan'
    }, [])
    useEffect(() => {
        if (getAllFoodTypesResult !== undefined)
            setFoodTypeList(getAllFoodTypesResult.data)
    }, [getAllFoodTypesResult])
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.foodTypeRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to={RouteString.foodTypeRoute.route} class="btn btn-outline-success" type="button">{RouteString.foodTypeRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary"
                            data-bs-toggle="modal" data-bs-target="#foodTypeAddModal"
                            type="button" >Thêm loại món ăn</button>
                        <FoodTypeAddModal />
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <FoodTypeList
                                props={{ foodTypeList }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FoodTypePage