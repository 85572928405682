import AdminBlockModal from "./AdminBlockModal"
import { getAllAdmins } from '../../Store/Admin/slice'
import { useDispatch } from "react-redux"
import { useContext, useEffect, useState } from "react"
import axios from 'axios';
import { APIHost } from '../../Lib/Constant'
import { AuthContext } from '../../Context/ContextConfig'
import { toast } from "react-hot-toast";
import { MoonLoader } from 'react-spinners';
import React from "react"
const AdminInfo = ({ props }) => {
    const dispatch = useDispatch()
    const [imageSelected, setImageSelected] = useState(null)
    const [imageUploading, setImageUploading] = useState(false)
    const authContext = useContext(AuthContext)
    useEffect(() => {
        if (imageSelected !== null) {
            const formData = new FormData();
            formData.append('image', imageSelected);
            try {
                setImageUploading(true)
                axios({
                    url: `${APIHost}/update-avatar`,
                    method: 'post',
                    headers: { 'Authorization': `Bearer ${authContext.token}` },
                    data: formData
                }).then((result) => {
                    toast.remove()
                    setImageUploading(false)
                    if (result.data.success) {
                        toast.success(result.data.message)
                        dispatch(getAllAdmins())
                    }
                    else
                        toast.error(result.data.message.image)
                })
                setImageSelected(null)
            } catch (error) {
                toast.error(`Vui lòng thử lại sau`)
            }
        }
    }, [imageSelected])
    return (
        props.adminInfo !== null ?
            <div className="col-xl-8 xl-50 col-md-7">
                <AdminBlockModal props={{ adminInfo: props.adminInfo }} />
                <div className="tab-content">
                    <div className="tab-pane tab-content-child fade active show">
                        <div className="profile-mail">
                            <div className="media">
                                {
                                    imageUploading ?
                                        <MoonLoader
                                            color="#36d7b7"
                                        />
                                        :
                                        <>
                                            <img className="img-100 img-fluid m-r-20 rounded-circle update_img" crossOrigin="anonymouse" src={props.adminInfo.AVT} alt={props.adminInfo.Name} />
                                            <input className="updateimg" type="file"
                                                onChange={(e) => setImageSelected(e.target.files[0])}
                                                disabled={imageUploading}
                                            />
                                            <div className="media-body mt-0">
                                                <h5><span className="name">{props.adminInfo.Name}</span></h5>
                                                <p className="phone">0{props.adminInfo.Phone}</p>
                                                {props.adminInfo.State ?
                                                    <ul>
                                                        <li data-bs-toggle="modal" data-bs-target="#adminBlockModal">
                                                            <button className="btn btn-secondary">Khóa tài khoản <i className="icon-lock"></i></button>
                                                        </li>
                                                    </ul>
                                                    : <></>
                                                }
                                            </div>
                                        </>
                                }

                            </div>
                            <div className="email-general">
                                <h6 className="mb-3">Thông tin</h6>
                                <ul>
                                    <li>Họ tên <span className="font-primary">{props.adminInfo.Name}</span></li>
                                    <li>Số điện thoại<span className="font-primary">0{props.adminInfo.Phone}</span></li>
                                    <li>Địa chỉ<span className="font-primary">{props.adminInfo.Address}</span></li>
                                    <li>Trạng thái<span className="font-primary">{props.adminInfo.State ? 'Đang hoạt động' : 'Đã khóa'}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <></>
    )
}

export default AdminInfo