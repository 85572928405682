import { Link } from "react-router-dom"
import React from "react"
const FoodTypeList = ({ props }) => {

    return (
        (Array.isArray(props.foodTypeList) && props.foodTypeList.length > 0) ?
            props.foodTypeList.map((e) => (
                <div key={Math.random()} className="col-md-6 col-xxl-3 box-col-3" >
                    <Link to={`${e.Slug}`}>
                        <div className="card card-with-border bookmark-card o-hidden">
                            <div className="blog-box blog-grid text-center">
                                <img crossOrigin="anonymouse" className="img-fluid top-radius-blog" src={e.Image} alt="" />
                                <div className="blog-details-main">
                                    <hr />
                                    <h4 className="blog-bottom-details">
                                        {e.Name}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div >
            ))
            :
            <></>
    )
}

export default FoodTypeList