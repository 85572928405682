import React from "react"
const FoodListToSelectUpdateParty = ({ props }) => {
    const checkSelected = (food) => {
        return (props.foodSelected.findIndex(e => e.ID == food.ID) >= 0)
    }
    return (
        (Array.isArray(props.foodListToDisplay) && props.foodListToDisplay.length > 0) ?
            props.foodListToDisplay.map((e) => (
                <div className="col-xxl-3 col-sm-6" key={e.Slug}>
                    <div className="card-wrapper border rounded-3 checkbox-checked">
                        <h6 className="sub-title">{e.Name}</h6>
                        <div className="img-checkbox">
                            <input className="main-img-cover form-check-input"
                                id={e.Slug}
                                type="checkbox"
                                onChange={(event) => props.selectFood(e, event.target.checked)}
                                checked={checkSelected(e)}
                            />
                            <label className="form-check-label mb-0" htmlFor={e.Slug}>
                                <img
                                    crossOrigin="anonymouse"
                                    src={e.Image[0].image.IMAGEURL}
                                    alt={e.Name} />
                            </label>
                        </div>
                    </div>
                </div >
            ))
            :
            <></>
    )
}

export default FoodListToSelectUpdateParty