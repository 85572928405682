/** @format */

import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auths",
    initialState: {},
    reducers: {
        login: (state, { payload }) => ({
            ...state,
        }),
        loginResult: (state, { payload }) => ({
            ...state,
            loginResult: payload,
        }),
    },
});

export const {
    login,
    loginResult,
} = authSlice.actions;
export default authSlice.reducer;
