import { useDispatch, useSelector } from "react-redux"
import RouteString from "../../Lib/RouteString"
import { useEffect, useState } from "react"
import { getAllFoods } from '../../Store/Food/slice'
import { createMenu } from '../../Store/Menus/slice'
import { Link, useNavigate } from "react-router-dom"
import React from "react"
import { CurrentcyConvertVND } from "../../Lib/DateConvert"
import FoodListToSelect from "./FoodListToSelect"
import FoodSelectedList from "./FoodSelectedList"
import toast from "react-hot-toast"
const MenuCreatePage = () => {
    const dispatch = useDispatch()
    const { getAllFoodsResult } = useSelector(state => state.food)
    const { createMenuResult } = useSelector(state => state.menus)
    const [foodList, setFoodList] = useState([])
    const [foodListToDisplay, setFoodListToDisplay] = useState([])
    const [foodSelected, setFoodSelected] = useState([])
    const [priceTotal, setPriceTotal] = useState(0)
    const [textToSearchFood, setTextToSearchFood] = useState('')
    const [menuName, setMenuName] = useState('')
    const [menuType, setMenuType] = useState(1)
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getAllFoods())
        document.title = 'Tạo menu - Quán ăn Cường Loan'
    }, [])
    useEffect(() => {
        let foodListTemp = [...foodList]
        if (textToSearchFood !== '')
            foodListTemp = foodListTemp.filter(e => e.Name.toLowerCase().includes(textToSearchFood.toLowerCase()))
        setFoodListToDisplay(foodListTemp)
    }, [textToSearchFood])
    useEffect(() => {
        if (getAllFoodsResult !== undefined) {
            setFoodList(getAllFoodsResult.data)
            setFoodListToDisplay(getAllFoodsResult.data)
        }
    }, [getAllFoodsResult])
    useEffect(() => {
        setPriceTotal(foodSelected.reduce((sum, e) => sum + parseInt(e.price), 0))
    }, [foodSelected])
    useEffect(() => {
        if (createMenuResult != undefined) {
            toast.remove()
            if (!createMenuResult.success) {
                toast.error(createMenuResult.message)
            } else {
                toast.success(createMenuResult.message)
                navigate(`/update-menu/${createMenuResult.menu.MENUID}`)
            }
        }
    }, [createMenuResult])
    const selectFood = (food, state) => {
        let foodSelectedTemp = [...foodSelected]
        let index = foodSelectedTemp.findIndex(e => e.ID == food.ID)
        if (index >= 0)
            foodSelectedTemp.splice(index, 1)
        else if (state)
            foodSelectedTemp.push({
                ...food,
                price: null,
            })
        setFoodSelected(foodSelectedTemp)
    }
    const checkPriceOfFood = () => {
        let ok = true
        foodSelected.forEach(e => {
            if (e.price < 300000 && !(e.Name.toLowerCase().includes('trái cây') || e.Name.toLowerCase().includes('tráng miệng'))) {
                toast.error(`Mức giá của ${e.Name} chưa phù hợp, món chính thấp nhất là từ 300.000`)
                document.getElementById(e.Slug).focus()
                ok = false
            }
        })
        return ok
    }
    const createPartyHandle = () => {
        toast.remove()
        let ok = true
        if (menuName == '') {
            toast.error("Chưa nhập tên cho menu")
            document.getElementById('menuName').focus()
            ok = false
        }
        if ((foodSelected.length < 4 && menuType == 1) || (foodSelected.length < 5 && menuType == 2)) {
            toast.error("Menu mẫu tiệc tận nơi chọn ít nhất 4 món, tiệc cưới ít nhất 5 món")
            ok = false
        }
        if (ok && checkPriceOfFood())
            dispatch(createMenu({
                menuName,
                menuType,
                priceTotal,
                foodSelected: foodSelected
            }))
    }
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.menuCreateRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to={RouteString.menuCreateRoute.route} className="btn btn-outline-success" type="button">{RouteString.menuCreateRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-wrapper border rounded-3">
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <label className="form-label" htmlFor="menuName">Nhập tên cho menu</label>
                                            <input className="form-control"
                                                id="menuName"
                                                type="phone"
                                                placeholder="Nhập tên cho menu"
                                                value={menuName}
                                                onChange={(e) => setMenuName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="form-label" htmlFor="menuType">Chọn loại menu</label>
                                            <select className="form-select" id="menuType" onChange={(e) => setMenuType(e.target.value)}>
                                                <option value="1">Menu tiệc tận nơi</option>
                                                <option value="2">Menu tiệc cưới</option>
                                            </select>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr className="border-bottom-primary">
                                                        <th scope="col">#</th>
                                                        <th scope="col">Món ăn đã chọn</th>
                                                        <th scope="col">Mức giá</th>
                                                        <th scope="col">Mức giá đã nhập</th>
                                                        <th scope="col">Thao tác</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <FoodSelectedList
                                                        props={{
                                                            foodSelected,
                                                            setFoodSelected
                                                        }}
                                                    />
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="alert alert-success dark" role="alert">
                                            <p>Tổng giá trị một mâm: {CurrentcyConvertVND(priceTotal)}</p>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary" onClick={() => createPartyHandle()} >Tạo menu</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="col-md-12">
                                    <label className="form-label" htmlFor="textToSearchFood">Tìm món ăn</label>
                                    <input className="form-control"
                                        id="textToSearchFood"
                                        type="text"
                                        placeholder="Nhập tên món ăn cần tìm để tìm nhanh"
                                        value={textToSearchFood}
                                        onChange={(e) => setTextToSearchFood(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="main-img-checkbox">
                                    <div className="row g-3">
                                        <FoodListToSelect
                                            props={
                                                {
                                                    foodListToDisplay,
                                                    setFoodSelected,
                                                    foodSelected,
                                                    selectFood,
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuCreatePage