/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleGetAllFoodTypesAPI,
  handleGetFoodTypeDetailAPI,
  handleUpdateFoodTypeAPI,
  handleUpdateFoodTypeImageAPI,
  handleAddFoodTypeAPI
} from "./handlers";
import {
  getAllFoodTypes,
  getFoodTypeDetail,
  updateFoodType,
  updateFoodTypeImage,
  addFoodType
} from "./slice";

export default function* foodTypesWatcher() {
  yield takeLatest(getAllFoodTypes.type, handleGetAllFoodTypesAPI);
  yield takeLatest(getFoodTypeDetail.type, handleGetFoodTypeDetailAPI);
  yield takeLatest(updateFoodType.type, handleUpdateFoodTypeAPI);
  yield takeLatest(updateFoodTypeImage.type, handleUpdateFoodTypeImageAPI);
  yield takeLatest(addFoodType.type, handleAddFoodTypeAPI);
}
