/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleLoginAPI,
} from "./handlers";
import {
  login,
} from "./slice";

export default function* authsWatcher() {
  yield takeLatest(login.type, handleLoginAPI);
}
