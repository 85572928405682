/** @format */

import instance from '.';

export const createMenuAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/create-menu`,
        data: payload
    });
};

export const getMenusAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/menus`,
    });
};

export const getMenuDetailAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/menu/${payload}`,
    });
};

export const updateMenuAPI = async (payload) => {
    return await instance.request({
        method: "PUT",
        url: `/update-menu`,
        data: payload
    });
};