/** @format */

import { all, fork } from "redux-saga/effects";
import authsWatcher from "./Auth/saga";
import adminsWatcher from "./Admin/saga";
import foodTypesWatcher from "./FoodType/saga";
import foodsWatcher from "./Food/saga";
import ordersWatcher from "./Orders/saga";
import partiesWatcher from "./Parties/saga";
import menusWatcher from "./Menus/saga";

export default function* rootSaga() {
  yield all([
    fork(authsWatcher),
    fork(adminsWatcher),
    fork(foodTypesWatcher),
    fork(foodsWatcher),
    fork(ordersWatcher),
    fork(partiesWatcher),
    fork(menusWatcher),
  ]);
}
