/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleGetAllFoodsAPI,
  handleGetFoodDetailAPI,
  handleHideFoodAPI,
  handleShowFoodAPI
} from "./handlers";
import {
  getAllFoods,
  getFoodDetail,
  hideFood,
  showFood
} from "./slice";

export default function* foodsWatcher() {
  yield takeLatest(getAllFoods.type, handleGetAllFoodsAPI);
  yield takeLatest(getFoodDetail.type, handleGetFoodDetailAPI);
  yield takeLatest(hideFood.type, handleHideFoodAPI);
  yield takeLatest(showFood.type, handleShowFoodAPI);
}
