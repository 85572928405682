/** @format */

import instance from '.';

export const createPartyAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/create-party`,
        data: payload
    });
};

export const updatePartyAPI = async (payload) => {
    return await instance.request({
        method: "PUT",
        url: `/update-party`,
        data: payload
    });
};

export const getPartiesAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-parties/${payload}`,
    });
};


export const getPartyDetailAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-party/${payload}`,
    });
};

export const getAmLichAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/get-am-lich`,
        data: payload
    });
};