/** @format */

import {
    getAllAdminsAPI,
    getAdminAPI,
    revokeAdminAccountAPI,
    createAdminAccountAPI,
    updateAvatarAPI
} from '../../API/Admin';
import { call, put } from "redux-saga/effects";
import {
    getAllAdminsResult,
    getAdminResult,
    revokeAdminAccountResult,
    createAdminAccountResult,
    updateAvatarResult
} from "./slice";

function* handleGetAllAdminsAPI({ payload }) {
    try {
        const response = yield call(getAllAdminsAPI, payload);
        if (response.status === 200) {
            yield put(getAllAdminsResult(response.data));
        }
    } catch (error) {
        yield put(getAllAdminsResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetAdminAPI({ payload }) {
    try {
        const response = yield call(getAdminAPI, payload);
        if (response.status === 200) {
            yield put(getAdminResult(response.data));
        }
    } catch (error) {
        yield put(getAdminResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleRevokeAdminAccountAPI({ payload }) {
    try {
        const response = yield call(revokeAdminAccountAPI, payload);
        if (response.status === 200) {
            yield put(revokeAdminAccountResult(response.data));
        }
    } catch (error) {
        yield put(revokeAdminAccountResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleCreateAdminAccountAPI({ payload }) {
    try {
        const response = yield call(createAdminAccountAPI, payload);
        if (response.status === 200) {
            yield put(createAdminAccountResult(response.data));
        }
    } catch (error) {
        yield put(createAdminAccountResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleUpdateAvatarAPI({ payload }) {
    try {
        const response = yield call(updateAvatarAPI, payload);
        if (response.status === 200) {
            yield put(updateAvatarResult(response.data));
        }
    } catch (error) {
        yield put(updateAvatarResult({ success: false, code: Math.random(1000) }));
    }
}
export {
    handleGetAllAdminsAPI,
    handleGetAdminAPI,
    handleRevokeAdminAccountAPI,
    handleCreateAdminAccountAPI,
    handleUpdateAvatarAPI
};
