import React from "react"
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import RouteString from './../Lib/RouteString'
const Menu = () => {
    const navigate = useNavigate()
    const partyMenu = () => {
        swal("Chọn một mục để truy cập", {
            buttons: {
                pass: {
                    text: "Tiệc đã qua",
                },
                preparing: {
                    text: "Tiệc sắp đến",
                },
                create: {
                    text: "Tạo tiệc mới",
                },
            },
        }).then((value) => {
            switch (value) {
                case "pass":
                    navigate('/done-party/1')
                    break;

                case "preparing":
                    navigate('/party-preparing/1')
                    break;

                case "create":
                    navigate('/party-create')
                    break;
            }
        });
    }
    const orderMenu = () => {
        swal("Chọn một mục để truy cập", {
            buttons: {
                view: {
                    text: "Xem đơn đặt món",
                },
            },
        });
    }
    const foodMenu = () => {
        swal("Chọn một mục để truy cập", {
            buttons: {
                create: {
                    text: "Thêm món",
                },
                view: {
                    text: "Xem món ăn",
                },
                type: {
                    text: "Loại món ăn",
                },
            },
        }).then((value) => {
            switch (value) {
                case "type":
                    navigate('/food-types')
                    break;

                case "view":
                    navigate('/foods')
                    break;

                case "create":
                    navigate('/add-food')
                    break;
            }
        });
    }
    return (
        <div className="sidebar-wrapper">
            <div>
                <nav className="sidebar-main">
                    <ul className="pagination justify-content-center pagin-border-danger pagination-danger">
                        <li className="page-item"><Link className="page-link" to={RouteString.homeRoute.route}>{RouteString.homeRoute.txt}</Link></li>
                        <li className="page-item"><Link className="page-link" to={RouteString.ordersRoute.route}>Đặt món</Link></li>
                        <li className="page-item"><button className="page-link" onClick={() => partyMenu()}>Đặt tiệc</button></li>
                        <li className="page-item"><button className="page-link" onClick={() => foodMenu()}>Món ăn</button></li>
                        <li className="page-item"><Link className="page-link" to={RouteString.menuRoute.route}>Menu</Link></li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Menu;