/** @format */

import { createSlice } from "@reduxjs/toolkit";

const foodSlice = createSlice({
    name: "foods",
    initialState: {},
    reducers: {
        getAllFoods: (state, { payload }) => ({
            ...state,
        }),
        getAllFoodsResult: (state, { payload }) => ({
            ...state,
            getAllFoodsResult: payload,
        }),
        getFoodDetail: (state, { payload }) => ({
            ...state,
        }),
        getFoodDetailResult: (state, { payload }) => ({
            ...state,
            getFoodDetailResult: payload,
        }),
        hideFood: (state, { payload }) => ({
            ...state,
        }),
        hideFoodResult: (state, { payload }) => ({
            ...state,
            hideFoodResult: payload,
        }),
        showFood: (state, { payload }) => ({
            ...state,
        }),
        showFoodResult: (state, { payload }) => ({
            ...state,
            showFoodResult: payload,
        }),
    },
});

export const {
    getAllFoods,
    getAllFoodsResult,
    getFoodDetail,
    getFoodDetailResult,
    hideFood,
    hideFoodResult,
    showFood,
    showFoodResult
} = foodSlice.actions;
export default foodSlice.reducer;
