/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleGetAllOrdersAdminAPI,
  handleGetOrderDetailAdminAPI,
  handleUpdateOrderStateAdminAPI
} from "./handlers";
import {
  getAllOrdersAdmin,
  getOrderDetailAdmin,
  updateOrderStateAdmin
} from "./slice";

export default function* ordersWatcher() {
  yield takeLatest(getAllOrdersAdmin.type, handleGetAllOrdersAdminAPI);
  yield takeLatest(getOrderDetailAdmin.type, handleGetOrderDetailAdminAPI);
  yield takeLatest(updateOrderStateAdmin.type, handleUpdateOrderStateAdminAPI);
}
