/** @format */

import instance from '.';

export const getAllAdminsAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/`,
    });
};
export const getAdminAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/${payload}`,
    });
};
export const revokeAdminAccountAPI = async (payload) => {
    return await instance.request({
        method: "PUT",
        url: `/revoke-account`,
        data: payload
    });
};
export const createAdminAccountAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/`,
        data: payload
    });
};
export const updateAvatarAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/update-avatar`,
        data: payload
    });
};