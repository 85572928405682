/** @format */

import instance from '.';

export const getAllFoodTypesAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-food-types`,
    });
};

export const getFoodTypeDetailAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-food-type-detail/${payload}`,
    });
};

export const updateFoodTypeAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/edit-food-type/${payload.foodtypeslug}`,
        data: payload
    });
};

export const updateFoodTypeImageAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/edit-food-type-image/${payload.foodtypeslug}`,
        data: payload
    });
};

export const addFoodTypeAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/add-food-type`,
        data: payload
    });
};