/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleCreatePartyAPI,
  handleGetPartyDetailAPI,
  handleUpdatePartyAPI,
  handleGetAmLichAPI,
  handleGetPartiesAPI
} from "./handlers";
import {
  createParty,
  getPartyDetail,
  updateParty,
  getAmLich,
  getParties
} from "./slice";

export default function* partiesWatcher() {
  yield takeLatest(createParty.type, handleCreatePartyAPI);
  yield takeLatest(getPartyDetail.type, handleGetPartyDetailAPI);
  yield takeLatest(updateParty.type, handleUpdatePartyAPI);
  yield takeLatest(getAmLich.type, handleGetAmLichAPI);
  yield takeLatest(getParties.type, handleGetPartiesAPI);
}
