import { useContext, useState } from "react"
import { useDispatch } from "react-redux"
import { vietnameseName } from '../../Lib/Regex'
import { toast } from "react-hot-toast"
import { APIHost } from "../../Lib/Constant"
import { AuthContext } from "../../Context/ContextConfig"
import { MoonLoader } from 'react-spinners';
import axios from "axios"
import { getAllFoodTypes } from '../../Store/FoodType/slice'
import React from "react"
const FoodTypeAddModal = () => {
    const [foodTypeName, setFoodTypeName] = useState('')
    const [foodTypeImage, setFoodTypeImage] = useState(null)
    const [addLoading, setAddLoading] = useState(null)
    const authContext = useContext(AuthContext)
    const dispatch = useDispatch()
    const addFoodTypeHandle = () => {
        toast.remove()
        if (!vietnameseName.test(foodTypeName))
            toast.error('Tên loại món ăn chưa hợp lệ!')
        if (foodTypeImage === null)
            toast.error('Hình ảnh loại món ăn chưa hợp lệ!')
        if (vietnameseName.test(foodTypeName) && foodTypeImage !== null) {
            const formData = new FormData();
            formData.append('image', foodTypeImage);
            formData.append('foodtypename', foodTypeName);
            try {
                setAddLoading(true)
                axios({
                    url: `${APIHost}/add-food-type`,
                    method: 'post',
                    headers: { 'Authorization': `Bearer ${authContext.token}` },
                    data: formData
                }).then((result) => {
                    toast.remove()
                    setAddLoading(false)
                    if (result.data.success) {
                        toast.success(result.data.message)
                        dispatch(getAllFoodTypes())
                        setFoodTypeImage(null)
                        setFoodTypeName('')
                    }
                    else
                        toast.error(result.data.message)
                })
            } catch (error) {
                toast.error(`Vui lòng thử lại sau`)
            }
        }
    }
    return (
        <div className="modal fade" id="foodTypeAddModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        {
                            addLoading ?
                                <MoonLoader
                                    color="#36d7b7"
                                />
                                :
                                <>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="foodTypeAddModalName">Tên loại món ăn:</label>
                                                <input className="form-control"
                                                    value={foodTypeName}
                                                    onChange={(e) => setFoodTypeName(e.target.value)}
                                                    id="foodTypeAddModalName"
                                                    type="text" placeholder="Nhập tên loại món ăn" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="foodTypeAddModalFile">File ảnh:</label>
                                                <input className="form-control"
                                                    onChange={(e) => setFoodTypeImage(e.target.files[0])}
                                                    id="foodTypeAddModalFile"
                                                    type="file" />
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary"
                            onClick={() => addFoodTypeHandle()}
                            type="button">Thêm loại món ăn</button>
                        <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Đóng</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoodTypeAddModal