import AdminList from "./AdminList"
import AdminInfo from "./AdminInfo"
import AdminCreateModal from './AdminCreateModal'
import RouteString from "../../Lib/RouteString"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getAllAdmins, getAdmin } from '../../Store/Admin/slice'
import React from "react"
const AdminPage = () => {
    const dispatch = useDispatch()
    const { getAllAdminsResult, getAdminResult } = useSelector(state => state.admin)
    const [adminList, setAdminList] = useState([])
    const [adminActive, setAdminActive] = useState(0)
    const [adminInfo, setAdminInfo] = useState(null)
    useEffect(() => {
        dispatch(getAllAdmins())
    }, [])
    useEffect(() => {
        if (getAdminResult !== undefined) {
            setAdminInfo(getAdminResult.data)
        }
    }, [getAdminResult])
    useEffect(() => {
        if (adminList.length !== 0)
            dispatch(getAdmin(`0${adminList[adminActive].Phone}`))
    }, [adminActive])
    useEffect(() => {
        if (getAllAdminsResult !== undefined) {
            setAdminList(getAllAdminsResult.data)
            dispatch(getAdmin(`0${getAllAdminsResult.data[adminActive].Phone}`))
        }
    }, [getAllAdminsResult])
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.adminRoute.txt}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="email-wrap bookmark-wrap">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 box-col-12">
                            <div className="bookmark-tabcontent contacts-tabs">
                                <div className="card">
                                    <div className="ps-0">
                                        <div className="tab-content">
                                            <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="pills-personal-tab">
                                                <div className="card mb-0">
                                                    <div className="card-header d-flex">
                                                        <button className="btn btn-primary"
                                                            data-bs-toggle="modal" data-bs-target="#adminCreateModal"
                                                            type="button">Thêm quản trị viên mới <i className="icon-user"></i></button>
                                                        <AdminCreateModal />
                                                    </div>
                                                    <div className="card-body p-0">
                                                        <div className="row list-persons">
                                                            <AdminList props={{ adminList, adminActive, setAdminActive }} />
                                                            <AdminInfo props={{ adminInfo }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPage