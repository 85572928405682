import { useDispatch, useSelector } from "react-redux"
import RouteString from "../../Lib/RouteString"
import { useEffect, useState } from "react"
import { getParties } from '../../Store/Parties/slice'
import { Link, useNavigate, useParams } from "react-router-dom"
import React from "react"
import PartyList from "./PartyList"
const PartyPreparePage = () => {
    const dispatch = useDispatch()
    const { getPartiesResult } = useSelector(state => state.parties)
    const [partyList, setPartyList] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 0,
        last_page: 0,
        from: 1
    })
    const { page } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getParties(page))
        document.title='Tiệc sắp tới - Quán ăn Cường Loan'
    }, [page])
    useEffect(() => {
        if (getPartiesResult !== undefined)
            if (getPartiesResult.success) {
                setPartyList(getPartiesResult.partysChuaToi.data)
                setPagination({
                    current_page: getPartiesResult.partysChuaToi.current_page,
                    last_page: getPartiesResult.partysChuaToi.last_page,
                    from: getPartiesResult.partysChuaToi.from,
                })
                if (getPartiesResult.partysChuaToi.last_page < page)
                    navigate(`/party-preparing/${getPartiesResult.partysChuaToi.last_page}`)
            }
    }, [getPartiesResult])
    const generationPagination = () => {
        let paginationList = []
        for (let i = 1; i <= pagination.last_page; i++) {
            paginationList.push(
                <li key={i} className={`page-item ${i == page ? 'active' : ''}`}><Link className="page-link" to={`/party-preparing/${i}`}>{i}</Link></li>
            )
        }
        return paginationList
    }
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.partyPrepareRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to='/party-preparing/1' className="btn btn-outline-success" type="button">{RouteString.partyPrepareRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4>{RouteString.partyPrepareRoute.txt}</h4>
                        </div>
                        <div className="table-responsive signal-table">
                            <PartyList
                                props={{ partyList, setPartyList, pagination }}
                            />
                        </div>
                        <div className="card-body">
                            <nav aria-label="...">
                                <ul className="pagination pagination-success pagin-border-success">
                                    <li className={`page-item ${page == 1 ? 'disabled' : ''}`}><Link className="page-link" to={`${page == 1 ? '#' : `/party-preparing/${parseInt(page) - 1}`}`}>Trang trước</Link></li>
                                    {
                                        generationPagination().map(e => e)
                                    }
                                    <li className={`page-item ${page == pagination.last_page ? 'disabled' : ''}`}><Link className="page-link" to={`${page == pagination.last_page ? '#' : `/party-preparing/${parseInt(page) + 1}`}`}>Trang sau</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartyPreparePage