import { useDispatch, useSelector } from "react-redux"
import RouteString from "../../Lib/RouteString"
import { useEffect, useState } from "react"
import { getOrderDetailAdmin, updateOrderStateAdmin } from '../../Store/Orders/slice'
import { Link, useParams } from "react-router-dom"
import React from "react"
import OrderDetailFoodList from "./OrderDetailFoodList"
import { DateTimeFromTimestamp } from '../../Lib/DateConvert'
import { textStateOfOrder } from "../../Lib/StateOfOrder"

const OrderDetailPage = () => {
    const dispatch = useDispatch()
    const { getOrderDetailAdminResult, updateOrderStateAdminResult } = useSelector(state => state.orders)
    const { orderID } = useParams()
    const [orderDetail, setOrderDetail] = useState(0)
    const [disableButton, setDisableButton] = useState(false)
    useEffect(() => {
        dispatch(getOrderDetailAdmin(orderID))
        document.title='Xem đơn đặt/gọi món ăn - Quán ăn Cường Loan'
    }, [orderID])
    useEffect(() => {
        if (getOrderDetailAdminResult !== undefined)
            if (getOrderDetailAdminResult.success)
                setOrderDetail(getOrderDetailAdminResult.order)
            else
                setOrderDetail(null)
    }, [getOrderDetailAdminResult])
    useEffect(() => {
        if (updateOrderStateAdminResult !== undefined)
            dispatch(getOrderDetailAdmin(orderID))
        setDisableButton(false)
    }, [updateOrderStateAdminResult])
    const updateOrderState = () => {
        dispatch(updateOrderStateAdmin(orderID))
        setDisableButton(true)
    }
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.orderDetailRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to={RouteString.ordersRoute.route} className="btn btn-outline-success" type="button">{RouteString.ordersRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-body">
                        {
                            orderDetail === 0 ? (
                                <div className="alert alert-light-primary" role="alert">
                                    <h5 className="alert-heading pb-2 txt-primary">Đang tải đơn</h5>
                                    <p>Đang tải đơn đặt món ăn, vui lòng đợi!</p>
                                </div>
                            ) :
                                orderDetail === null ? (
                                    <div className="alert alert-light-secondary" role="alert">
                                        <h5 className="alert-heading pb-2 txt-secondary">Đơn món ăn không tồn tại</h5>
                                        <p>Đơn đặt món ăn này không tồn tại, quay lại trang trước để chọn đơn món ăn phù hợp.</p>
                                    </div>

                                ) :
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Mã đơn đặt món</th>
                                                    <th scope="col">Tên khách</th>
                                                    <th scope="col">SĐT khách</th>
                                                    <th scope="col">Địa chỉ</th>
                                                    <th scope="col">IP khách</th>
                                                    <th scope="col">Ngày đặt</th>
                                                    <th scope="col">Trạng thái đơn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">{orderID}</th>
                                                    <td>{`${orderDetail.OrderCustomer.CUSTOMERGENDER ? 'Anh' : 'Chị'} ${orderDetail.OrderCustomer.CUSTOMERNAME}`}</td>
                                                    <td>0{orderDetail.OrderCustomer.CUSTOMERPHONE}</td>
                                                    <td>{orderDetail.OrderCustomer.CUSTOMERADDRESS}</td>
                                                    <td>{orderDetail.OrderCustomer.CUSTOMERIP}</td>
                                                    <td>{DateTimeFromTimestamp(orderDetail.OrderTime)}</td>
                                                    <td>{textStateOfOrder(orderDetail.OrderStateCode, orderDetail.OrderState)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        < OrderDetailFoodList
                                            props={{ OrderDetail: JSON.parse(orderDetail.OrderDetail) }}
                                        />
                                    </div>

                        }
                    </div>
                    {
                        orderDetail === 0 ? (
                            <></>
                        ) :
                            orderDetail === null ? (
                                <></>
                            ) :
                                orderDetail.OrderStateCode === 4 ? (
                                    <></>
                                ) :
                                    <div className="card-footer">
                                        <button disabled={disableButton} className="btn btn-outline-secondary btn-sm" onClick={() => updateOrderState()}>Tiếp tục</button>
                                    </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default OrderDetailPage