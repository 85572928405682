import { useDispatch, useSelector } from "react-redux"
import RouteString from "../../Lib/RouteString"
import { useEffect, useState } from "react"
import { getAllFoods } from '../../Store/Food/slice'
import { createParty, getAmLich } from '../../Store/Parties/slice'
import { Link, useNavigate } from "react-router-dom"
import React from "react"
import { CurrentcyConvertVND, getCurrentDateTime, TimestampToDDMMYYYY } from "../../Lib/DateConvert"
import FoodListToSelect from "./FoodListToSelect"
import FoodSelectedList from "./FoodSelectedList"
import toast from "react-hot-toast"
import { vietnamePhone, vietnameseName } from "../../Lib/Regex"
const PartyCreatePage = () => {
    const dispatch = useDispatch()
    const { getAllFoodsResult } = useSelector(state => state.food)
    const { createPartyResult, getAmLichResult } = useSelector(state => state.parties)

    const [customerPhone, setCustomerPhone] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [partyTime, setPartyTime] = useState(getCurrentDateTime())
    const [partyPlace, setPartyPlace] = useState('')
    const [partyTables, setPartyTables] = useState(1)
    const [partyTableChair, setPartyTableChair] = useState(true)
    const [partyNote, setPartyNote] = useState('')
    const [foodList, setFoodList] = useState([])
    const [foodListToDisplay, setFoodListToDisplay] = useState([])
    const [foodSelected, setFoodSelected] = useState([])
    const [priceTotal, setPriceTotal] = useState(0)
    const [textToSearchFood, setTextToSearchFood] = useState('')
    const [ngayDaiAmLich, setNgayDaiAmLich] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getAllFoods())
        document.title='Tạo đơn đặt tiệc - Quán ăn Cường Loan'
    }, [])
    useEffect(() => {
        let foodListTemp = [...foodList]
        if (textToSearchFood !== '')
            foodListTemp = foodListTemp.filter(e => e.Name.toLowerCase().includes(textToSearchFood.toLowerCase()))
        setFoodListToDisplay(foodListTemp)
    }, [textToSearchFood])
    useEffect(() => {
        if (getAllFoodsResult !== undefined) {
            setFoodList(getAllFoodsResult.data)
            setFoodListToDisplay(getAllFoodsResult.data)
        }
    }, [getAllFoodsResult])
    useEffect(() => {
        setPriceTotal(foodSelected.reduce((sum, e) => sum + parseInt(e.partyPrice), 0))
    }, [foodSelected])
    useEffect(() => {
        setNgayDaiAmLich('đang tính ngày')
        dispatch(getAmLich({
            "date": TimestampToDDMMYYYY(partyTime)
        }))
    }, [partyTime])
    useEffect(() => {
        if (getAmLichResult != undefined)
            setNgayDaiAmLich(getAmLichResult.date)
    }, [getAmLichResult])
    useEffect(() => {
        if (createPartyResult != undefined) {
            toast.remove()
            if (!createPartyResult.success) {
                toast.error(createPartyResult.message)
            } else {
                toast.success(createPartyResult.message)
                navigate(`/party-update/${createPartyResult.party.PARTYID}`)
            }
        }
    }, [createPartyResult])
    const selectFood = (food, state) => {
        let foodSelectedTemp = [...foodSelected]
        let index = foodSelectedTemp.findIndex(e => e.ID == food.ID)
        if (index >= 0)
            foodSelectedTemp.splice(index, 1)
        else if (state)
            foodSelectedTemp.push({
                ...food,
                partyPrice: null,
            })
        setFoodSelected(foodSelectedTemp)
    }
    const checkPriceOfFood = () => {
        let ok = true
        foodSelected.forEach(e => {
            if (e.partyPrice < 300000 && !(e.Name.toLowerCase().includes('trái cây') || e.Name.toLowerCase().includes('tráng miệng'))) {
                toast.error(`Mức giá của ${e.Name} chưa phù hợp, món chính thấp nhất là từ 300.000`)
                document.getElementById(e.Slug).focus()
                ok = false
            }
        })
        return ok
    }
    const createPartyHandle = () => {
        toast.remove()
        let ok = true
        if (customerPhone.match(vietnamePhone) === null) {
            toast.error("Số điện thoại chưa đúng định dạng")
            document.getElementById('customerPhonenumber').focus()
            ok = false
        }
        if (partyTables < 1) {
            toast.error("Số mâm nhận ít nhất là 1")
            document.getElementById('partyTables').focus()
            ok = false
        }
        if (!vietnameseName.test(customerName)) {
            toast.error('Tên khách hàng chưa phù hợp')
            document.getElementById('customerName').focus()
            ok = false
        }
        if (partyPlace == '') {
            toast.error("Chưa nhập nơi đãi tiệc")
            document.getElementById('partyPlace').focus()
            ok = false
        }
        if (foodSelected.length < 1) {
            toast.error("Chưa chọn món ăn nào, nếu khách chỉ đặt ngày trước thì cứ chọn đại một món ăn")
            ok = false
        }
        if (new Date(partyTime).valueOf() <= new Date(getCurrentDateTime()).valueOf()) {
            toast.error("Ngày đãi tiệc phải từ hôm nay trở về sau")
            ok = false
        }
        if (ok && checkPriceOfFood())
            dispatch(createParty({
                customerPhone,
                customerName,
                partyTime,
                partyPlace,
                partyTables,
                partyTableChair,
                partyNote,
                foodSelected: foodSelected
            }))
    }
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.partyCreateRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to={RouteString.partyCreateRoute.route} className="btn btn-outline-success" type="button">{RouteString.partyCreateRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-wrapper border rounded-3">
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <label className="form-label" htmlFor="customerPhonenumber">Số điện thoại khách hàng</label>
                                            <input className="form-control"
                                                id="customerPhonenumber"
                                                type="phone"
                                                placeholder="Nhập số điện thoại khách hàng"
                                                value={customerPhone}
                                                onChange={(e) => setCustomerPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="form-label" htmlFor="customerName">Tên khách hàng</label>
                                            <input className="form-control"
                                                id="customerName"
                                                type="phone"
                                                placeholder="Nhập tên của khách hàng, VD: anh A, chị B"
                                                value={customerName}
                                                onChange={(e) => setCustomerName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <h5 className="form-label" htmlFor="partyTime">Ngày/giờ đãi tiệc, đã chọn <span className="badge badge-success">{`${new Date(partyTime).getHours() > 12 ? new Date(partyTime).getHours() - 12 : new Date(partyTime).getHours()} giờ ${new Date(partyTime).getMinutes()} ${new Date(partyTime).getHours() > 12 ? 'Chiều' : 'Sáng '}`}</span> ngày <span className="badge badge-success">{TimestampToDDMMYYYY(partyTime)} Dương lịch</span> tức <span className="badge badge-info">{ngayDaiAmLich} Âm lịch</span></h5>
                                            <input className="form-control"
                                                id="partyTime"
                                                type="datetime-local"
                                                value={partyTime}
                                                onChange={(e) => setPartyTime(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="form-label" htmlFor="partyTables">Số bàn tiệc (tối thiểu là 1)</label>
                                            <input className="form-control"
                                                id="partyTables"
                                                type="number"
                                                min={1}
                                                max={100}
                                                placeholder="Nhập số bàn tiệc"
                                                value={partyTables}
                                                onChange={(e) => setPartyTables(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="form-label" htmlFor="partyPlace">Địa điểm đãi tiệc</label>
                                            <textarea className="form-control"
                                                id="partyPlace"
                                                type="text"
                                                placeholder="Nhập địa điểm đãi tiệc"
                                                rows={3}
                                                value={partyPlace}
                                                onChange={(e) => setPartyPlace(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="form-label" htmlFor="partyNote">Ghi chú</label>
                                            <textarea className="form-control"
                                                id="partyNote"
                                                type="text"
                                                placeholder="Nhập ghi chú về việc, ví dụ: giảm đường, tặng nước đá, tặng trái cây, tặng,......"
                                                rows={3}
                                                value={partyNote}
                                                onChange={(e) => setPartyNote(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-check checkbox-checked">
                                                <input className="form-check-input" id="partyTableChair" type="checkbox" checked={partyTableChair} onChange={(e) => setPartyTableChair(!partyTableChair)} />
                                                <label className="form-check-label" htmlFor="partyTableChair">Sử dụng bàn ghế (4 món trở lên miễn phí, ít hơn 4 món là 50.000/1 bộ)</label>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr className="border-bottom-primary">
                                                        <th scope="col">#</th>
                                                        <th scope="col">Món ăn đã chọn</th>
                                                        <th scope="col">Mức giá</th>
                                                        <th scope="col">Mức giá đã nhập</th>
                                                        <th scope="col">Thao tác</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <FoodSelectedList
                                                        props={{
                                                            foodSelected,
                                                            setFoodSelected
                                                        }}
                                                    />
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="alert alert-success dark" role="alert">
                                            <p>Tổng giá trị một mâm: {CurrentcyConvertVND(priceTotal + ((foodSelected.length <= 3 && partyTableChair) ? 50000 : 0))}</p>
                                        </div>
                                        <div className="alert alert-success dark" role="alert">
                                            <p>Tổng giá trị tiệc {`${partyTables} mâm`}: {CurrentcyConvertVND(priceTotal * partyTables + ((foodSelected.length <= 3 && partyTableChair) ? 50000 * partyTables : 0))}</p>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary" onClick={() => createPartyHandle()} >Tạo đơn</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="col-md-12">
                                    <label className="form-label" htmlFor="textToSearchFood">Tìm món ăn</label>
                                    <input className="form-control"
                                        id="textToSearchFood"
                                        type="text"
                                        placeholder="Nhập tên món ăn cần tìm để tìm nhanh"
                                        value={textToSearchFood}
                                        onChange={(e) => setTextToSearchFood(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="main-img-checkbox">
                                    <div className="row g-3">
                                        <FoodListToSelect
                                            props={
                                                {
                                                    foodListToDisplay,
                                                    setFoodSelected,
                                                    foodSelected,
                                                    selectFood,
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartyCreatePage