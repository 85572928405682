/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleCreateMenuAPI,
  handleGetMenusAPI,
  handleGetMenuDetailAPI,
  handleUpdateMenuAPI
} from "./handlers";
import {
  createMenu,
  getMenus,
  getMenuDetail,
  updateMenu
} from "./slice";

export default function* menusWatcher() {
  yield takeLatest(createMenu.type, handleCreateMenuAPI);
  yield takeLatest(getMenus.type, handleGetMenusAPI);
  yield takeLatest(getMenuDetail.type, handleGetMenuDetailAPI);
  yield takeLatest(updateMenu.type, handleUpdateMenuAPI);
}
