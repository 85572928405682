/** @format */
import { APIHost } from "../Lib/Constant";
import axios from "axios";
import Cookies from "js-cookie";
const getCSRF = async () => {
    // await axios.get('https://lieutuanvu.io.vn/sanctum/csrf-cookie', {
    //     responseType: 'headers' // Xác định loại dữ liệu muốn nhận từ response
    // }).then((response) => {
    //     console.log(response.headers)
    // });

    // Thêm CSRF token vào header của yêu cầu Axios
    // return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}
const instance = axios.create({
    baseURL: APIHost,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
})

// Add a request interceptor
instance.interceptors.request.use(async (req) => {
    if (localStorage.getItem('token')) {
        req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    return req;
});

// Add a response interceptor
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        const status = error.response ? error.response.status : 500;
        if (status && status === 500) {
            localStorage.clear();
        }
        return Promise.reject(error);
    }
);

export default instance;
