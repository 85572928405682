/** @format */

import {
    getAllFoodTypesAPI,
    getFoodTypeDetailAPI,
    updateFoodTypeAPI,
    updateFoodTypeImageAPI,
    addFoodTypeAPI
} from '../../API/FoodType';
import { call, put } from "redux-saga/effects";
import {
    getAllFoodTypesResult,
    getFoodTypeDetailResult,
    updateFoodTypeResult,
    updateFoodTypeImageResult,
    addFoodTypeResult
} from "./slice";

function* handleGetAllFoodTypesAPI({ payload }) {
    try {
        const response = yield call(getAllFoodTypesAPI, payload);
        if (response.status === 200) {
            yield put(getAllFoodTypesResult(response.data));
        }
    } catch (error) {
        yield put(getAllFoodTypesResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetFoodTypeDetailAPI({ payload }) {
    try {
        const response = yield call(getFoodTypeDetailAPI, payload);
        if (response.status === 200) {
            yield put(getFoodTypeDetailResult(response.data));
        }
    } catch (error) {
        yield put(getFoodTypeDetailResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleUpdateFoodTypeAPI({ payload }) {
    try {
        const response = yield call(updateFoodTypeAPI, payload);
        if (response.status === 200) {
            yield put(updateFoodTypeResult(response.data));
        }
    } catch (error) {
        yield put(updateFoodTypeResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleUpdateFoodTypeImageAPI({ payload }) {
    try {
        const response = yield call(updateFoodTypeImageAPI, payload);
        if (response.status === 200) {
            yield put(updateFoodTypeImageResult(response.data));
        }
    } catch (error) {
        yield put(updateFoodTypeImageResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleAddFoodTypeAPI({ payload }) {
    try {
        const response = yield call(addFoodTypeAPI, payload);
        if (response.status === 200) {
            yield put(addFoodTypeResult(response.data));
        }
    } catch (error) {
        yield put(addFoodTypeResult({ success: false, code: Math.random(1000) }));
    }
}

export {
    handleGetAllFoodTypesAPI,
    handleGetFoodTypeDetailAPI,
    handleUpdateFoodTypeAPI,
    handleUpdateFoodTypeImageAPI,
    handleAddFoodTypeAPI
};
