import { useContext, useEffect, useState } from "react"
import { vietnamePhone } from '../../Lib/Regex'
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { login } from '../../Store/Auth/slice'
import { AuthContext } from "../../Context/ContextConfig"
import { useNavigate } from "react-router-dom"
import RouteString from '../../Lib/RouteString'
import React from "react"
const LoginPage = () => {
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const { loginResult } = useSelector(state => state.auth)
    const authContext = useContext(AuthContext)
    const navigate = useNavigate()
    const loginHandle = () => {
        toast.remove()
        if (phone.match(vietnamePhone) === null)
            toast.error("Số điện thoại chưa đúng định dạng")
        if (password === '') {
            toast.error("Chưa nhập mật khẩu")
        }
        if (phone.match(vietnamePhone) !== null && password !== '')
            dispatch(login({ phone, password }))
    }
    useEffect(() => {
        if (loginResult !== undefined) {
            if (loginResult.success === false)
                toast.error("Thông tin đăng nhập chưa đúng")
            else {
                toast.success("Đăng nhập thành công")
                authContext.setLogin(loginResult.token)
                localStorage.setItem("token", loginResult.token)
                localStorage.setItem("user", JSON.stringify(loginResult.user))
                navigate(RouteString.homeRoute.route)
            }
        }
    }, [loginResult])
    useEffect(() => {
        document.title = 'Đăng nhập - Quán ăn Cường Loan'

    }, [])

    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="login-card">
                        <div>
                            <div className="login-main">
                                <form className="theme-form">
                                    <h4>Đăng nhập</h4>
                                    <p>Sử dụng tài khoản được cấp để đăng nhập quản trị</p>
                                    <div className="form-group">
                                        <label className="col-form-label">Số điện thoại</label>
                                        <input className="form-control"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            type="email"
                                            placeholder="Nhập số điện thoại" />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Mật khẩu</label>
                                        <input className="form-control"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="password"
                                            placeholder="Nhập mật khẩu" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="text-end mt-3">
                                            <button className="btn btn-primary btn-block w-100" type="button"
                                                onClick={() => loginHandle()}
                                            >
                                                Đăng nhập
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage