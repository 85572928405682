/** @format */

import {
    createMenuAPI,
    getMenusAPI,
    getMenuDetailAPI,
    updateMenuAPI
} from '../../API/Menus';
import { call, put } from "redux-saga/effects";
import {
    createMenuResult,
    getMenusResult,
    getMenuDetailResult,
    updateMenuResult
} from "./slice";

function* handleCreateMenuAPI({ payload }) {
    try {
        const response = yield call(createMenuAPI, payload);
        if (response.status === 200) {
            yield put(createMenuResult(response.data));
        }
    } catch (error) {
        yield put(createMenuResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetMenusAPI({ payload }) {
    try {
        const response = yield call(getMenusAPI, payload);
        if (response.status === 200) {
            yield put(getMenusResult(response.data));
        }
    } catch (error) {
        yield put(getMenusResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetMenuDetailAPI({ payload }) {
    try {
        const response = yield call(getMenuDetailAPI, payload);
        if (response.status === 200) {
            yield put(getMenuDetailResult(response.data));
        }
    } catch (error) {
        yield put(getMenuDetailResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleUpdateMenuAPI({ payload }) {
    try {
        const response = yield call(updateMenuAPI, payload);
        if (response.status === 200) {
            yield put(updateMenuResult(response.data));
        }
    } catch (error) {
        yield put(updateMenuResult({ success: false, code: Math.random(1000) }));
    }
}

export {
    handleCreateMenuAPI,
    handleGetMenusAPI,
    handleGetMenuDetailAPI,
    handleUpdateMenuAPI
};
