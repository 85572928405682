import React from "react"
export const textStateOfOrder = (code, txt) => {
    switch (code) {
        case 1:
            return (
                <span className="badge badge-warning text-dark">{txt}</span>
            )
        case 2:
            return (
                <span className="badge badge-primary">{txt}</span>
            )
        case 3:
            return (
                <span className="badge badge-info">{txt}</span>
            )
        default:
            return (
                <span className="badge badge-success">{txt}</span>
            )
    }
}