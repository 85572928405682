/** @format */

import instance from '.';

export const getAllFoodsAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-foods`,
    });
};

export const getFoodDetailAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-food-detail/${payload}`,
    });
};

export const hideFoodAPI = async (payload) => {
    return await instance.request({
        method: "PUT",
        url: `/hide-food`,
        data: payload
    });
};

export const showFoodAPI = async (payload) => {
    return await instance.request({
        method: "PUT",
        url: `/show-food`,
        data: payload
    });
};