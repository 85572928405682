/** @format */

import {
    getAllOrdersAdminAPI,
    getOrderDetailAdminAPI,
    updateOrderStateAdminAPI
} from '../../API/Orders';
import { call, put } from "redux-saga/effects";
import {
    getAllOrdersAdminResult,
    getOrderDetailAdminResult,
    updateOrderStateAdminResult
} from "./slice";

function* handleGetAllOrdersAdminAPI({ payload }) {
    try {
        const response = yield call(getAllOrdersAdminAPI, payload);
        if (response.status === 200) {
            yield put(getAllOrdersAdminResult(response.data));
        }
    } catch (error) {
        yield put(getAllOrdersAdminResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetOrderDetailAdminAPI({ payload }) {
    try {
        const response = yield call(getOrderDetailAdminAPI, payload);
        if (response.status === 200) {
            yield put(getOrderDetailAdminResult(response.data));
        }
    } catch (error) {
        yield put(getOrderDetailAdminResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleUpdateOrderStateAdminAPI({ payload }) {
    try {
        const response = yield call(updateOrderStateAdminAPI, payload);
        if (response.status === 200) {
            yield put(updateOrderStateAdminResult(response.data));
        }
    } catch (error) {
        yield put(updateOrderStateAdminResult({ success: false, code: Math.random(1000) }));
    }
}
export {
    handleGetAllOrdersAdminAPI,
    handleGetOrderDetailAdminAPI,
    handleUpdateOrderStateAdminAPI
};
