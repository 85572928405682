/** @format */

import instance from '.';

export const getAllOrdersAdminAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-orders`,
    });
};
export const getOrderDetailAdminAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/get-order/${payload}`,
    });
};
export const updateOrderStateAdminAPI = async (payload) => {
    return await instance.request({
        method: "GET",
        url: `/update-order-state/${payload}`,
    });
};
