import { useDispatch, useSelector } from "react-redux"
import { revokeAdminAccount, getAllAdmins } from '../../Store/Admin/slice'
import { toast } from "react-hot-toast"
import React from "react"
const AdminBlockModal = ({ props }) => {
    const dispatch = useDispatch()
    const { revokeAdminAccountResult } = useSelector(state => state.admin)
    const revokeAdminAccountHandle = () => {
        dispatch(revokeAdminAccount({ adminphone: `0${props.adminInfo.Phone}` }))
        toast.success(`Khóa tài khoản quản trị ${props.adminInfo.Name} 0${props.adminInfo.Phone} thành công!`)
        dispatch(getAllAdmins())
    }
    return (
        <div className="modal fade" id="adminBlockModal" tabIndex="-1" style={{ display: 'none' }} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-toggle-wrapper">
                            <ul className="modal-img">
                                <li> <img src="../assets/gif/danger.gif" alt="error" /></li>
                            </ul>
                            <h4 className="text-center pb-2">Đang khóa tài khoản</h4>
                            <p className="text-center">Khóa tài khoản không thể hoàn tác!</p>
                            <p className="text-center">Xác nhận khóa tài khoản quản trị {props.adminInfo.Name} 0{props.adminInfo.Phone}?</p>
                            {/* <button className="btn btn-secondary d-flex m-auto" type="button" data-bs-dismiss="modal">Close</button> */}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            onClick={() => revokeAdminAccountHandle()}
                            className="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title=""
                        >Khóa tài khoản</button>
                        <button className="btn btn-primary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">Hủy</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminBlockModal