import { Link } from "react-router-dom"
import RouteString from "../../Lib/RouteString"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getAllFoods, hideFood, showFood } from '../../Store/Food/slice'
import FoodList from "./FoodList"
import React from "react"
const FoodPage = () => {
    const dispatch = useDispatch()
    const { getAllFoodsResult } = useSelector(state => state.food)
    const [foodList, setFoodList] = useState([])
    useEffect(() => {
        dispatch(getAllFoods())
        document.title='Món ăn - Quán ăn Cường Loan'
    }, [])
    useEffect(() => {
        if (getAllFoodsResult !== undefined) {
            setFoodList(getAllFoodsResult.data)
        }
    }, [getAllFoodsResult])

    const hideFoodHandle = (foodSlug) => {
        dispatch(hideFood({ foodSlug }))
        dispatch(getAllFoods())
    }
    const showFoodHandle = (foodSlug) => {
        dispatch(showFood({ foodSlug }))
        dispatch(getAllFoods())
    }
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.foodRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to={RouteString.foodRoute.route} className="btn btn-outline-success" type="button">{RouteString.foodRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="email-wrap bookmark-wrap">
                    <div className="row">
                        <div className="col-xl-3 box-col-6">
                            <div className="md-sidebar">
                                <div className="md-sidebar-aside job-left-aside custom-scrollbar">
                                    <div className="email-left-aside">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="email-app-sidebar left-bookmark">
                                                    <ul className="nav main-menu" role="tablist">
                                                        <li className="nav-item">
                                                            <Link to={RouteString.foodAddRoute.route} className="badge-light-primary btn-block btn-mail" type="button" >{RouteString.foodAddRoute.txt}</Link>
                                                        </li>
                                                        <hr />
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-md-12 box-col-12">
                            <div className="email-right-aside bookmark-tabcontent">
                                <div className="card email-body radius-left">
                                    <div className="ps-0">
                                        <div className="tab-content">
                                            <div className="tab-pane fade active show" id="pills-created" role="tabpanel" aria-labelledby="pills-created-tab">
                                                <div className="card mb-0">
                                                    <div className="card-header d-flex">
                                                        <h6 className="mb-0">Món ăn</h6>
                                                    </div>
                                                    <FoodList props={{ foodList, hideFoodHandle, showFoodHandle }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoodPage