/** @format */

import {
    getAllFoodsAPI,
    getFoodDetailAPI,
    hideFoodAPI,
    showFoodAPI
} from '../../API/Food';
import { call, put } from "redux-saga/effects";
import {
    getAllFoodsResult,
    getFoodDetailResult,
    hideFoodResult,
    showFoodResult
} from "./slice";

function* handleGetAllFoodsAPI({ payload }) {
    try {
        const response = yield call(getAllFoodsAPI, payload);
        if (response.status === 200) {
            yield put(getAllFoodsResult(response.data));
        }
    } catch (error) {
        yield put(getAllFoodsResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetFoodDetailAPI({ payload }) {
    try {
        const response = yield call(getFoodDetailAPI, payload);
        if (response.status === 200) {
            yield put(getFoodDetailResult(response.data));
        }
    } catch (error) {
        yield put(getFoodDetailResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleHideFoodAPI({ payload }) {
    try {
        const response = yield call(hideFoodAPI, payload);
        if (response.status === 200) {
            yield put(hideFoodResult(response.data));
        }
    } catch (error) {
        yield put(hideFoodResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleShowFoodAPI({ payload }) {
    try {
        const response = yield call(showFoodAPI, payload);
        if (response.status === 200) {
            yield put(showFoodResult(response.data));
        }
    } catch (error) {
        yield put(showFoodResult({ success: false, code: Math.random(1000) }));
    }
}

export {
    handleGetAllFoodsAPI,
    handleGetFoodDetailAPI,
    handleHideFoodAPI,
    handleShowFoodAPI
};
