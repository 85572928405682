import React from "react"
import { CurrentcyConvertVND } from '../../Lib/DateConvert'
const FoodSelectedListUpdateMenu = ({ props }) => {
    const setPrice = (index, price) => {
        let foodTemp = [...props.foodSelected]
        foodTemp[index].Price = price
        props.setFoodSelected(foodTemp)
    }
    const unSelectFood = (food) => {
        let foodTemp = [...props.foodSelected]
        foodTemp.splice(foodTemp.findIndex(e => e.ID == food.ID), 1)
        props.setFoodSelected(foodTemp)
    }
    return (
        (Array.isArray(props.foodSelected) && props.foodSelected.length > 0) ?
            props.foodSelected.map((e, i) => (
                <tr key={e.ID} className="border-bottom-primary">
                    <th scope="row">{i + 1}</th>
                    <td> <img className="img-30 me-2" crossOrigin="anonymouse" src={e.Image[0]} alt={e.Name} />{e.Name}</td>
                    <td>
                        <input className="form-control" type="number"
                            defaultValue={e.Price}
                            onChange={(event) =>
                                setPrice(i, event.target.value)
                            }
                            id={e.Slug}
                        />
                    </td>
                    <td>{CurrentcyConvertVND(e.Price)}</td>
                    <td>
                        <button
                            className="btn btn-secondary btn-xs"
                            type="button"
                            onClick={() => unSelectFood(e)}
                        >
                            Bỏ chọn món ăn
                        </button>
                    </td>
                </tr>
            ))
            :
            <></>
    )
}

export default FoodSelectedListUpdateMenu