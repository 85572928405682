/** @format */

import { takeLatest } from "redux-saga/effects";
import {
  handleGetAllAdminsAPI,
  handleGetAdminAPI,
  handleRevokeAdminAccountAPI,
  handleCreateAdminAccountAPI,
  handleUpdateAvatarAPI
} from "./handlers";
import {
  getAllAdmins,
  getAdmin,
  revokeAdminAccount,
  createAdminAccount,
  updateAvatar
} from "./slice";

export default function* adminsWatcher() {
  yield takeLatest(getAllAdmins.type, handleGetAllAdminsAPI);
  yield takeLatest(getAdmin.type, handleGetAdminAPI);
  yield takeLatest(revokeAdminAccount.type, handleRevokeAdminAccountAPI);
  yield takeLatest(createAdminAccount.type, handleCreateAdminAccountAPI);
  yield takeLatest(updateAvatar.type, handleUpdateAvatarAPI);
}
