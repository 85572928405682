import { Link, useParams } from "react-router-dom"
import RouteString from "../../Lib/RouteString"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFoodDetail } from '../../Store/Food/slice'
import React from "react"
const FoodDetailPage = () => {
    const { foodSlug } = useParams()
    const [foodDetail, setFoodDetail] = useState(null)
    const dispatch = useDispatch()
    const { getFoodDetailResult } = useSelector(state => state.food)
    useEffect(() => {
        if (foodSlug !== undefined)
            dispatch(getFoodDetail(foodSlug))
        document.title = 'Chi tiết món ăn - Quán ăn Cường Loan'
    }, [foodSlug])
    useEffect(() => {
        if (getFoodDetailResult !== undefined) {
            if (getFoodDetailResult.success == false)
                window.location = RouteString.foodRoute.route
            else setFoodDetail(getFoodDetailResult.data)
        }
    }, [getFoodDetailResult])
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.foodRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to={RouteString.foodRoute.route} className="btn btn-outline-success" type="button">{RouteString.foodRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            {
                foodDetail === null ?
                    <></>
                    :
                    <div className="container-fluid">
                        <div>
                            <div className="row product-page-main p-0">
                                <div className="col-xxl-6 col-md-6 box-col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>{foodDetail.Name}</h5>
                                        </div>
                                        <div className="card-body">
                                            <Carousel >
                                                {
                                                    foodDetail.Image.map((e, i) => (
                                                        <div key={i}>
                                                            <img crossOrigin="anonymous" src={e.image.IMAGEURL} alt={foodDetail.Name} />
                                                            <p className="legend">Ảnh {(i + 1)}</p>
                                                        </div>
                                                    ))
                                                }
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 box-col-6 order-xxl-0 order-1">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="product-page-details">
                                                <h3>{foodDetail.Name}</h3>
                                            </div>
                                            <hr />
                                            <div className="badge-spacing" >

                                                {
                                                    foodDetail.Variant.map((v) => (
                                                        <h3 className="pb-2 d-flex gap-2 flex-wrap" key={Math.random()}>
                                                            <span className="badge badge-warning">
                                                                {v.VARIANT} -
                                                                {v.RATION} người -
                                                                {v.PRICEVALUE.toLocaleString('vi-VN', {
                                                                    style: 'currency',
                                                                    currency: 'VND',
                                                                })}</span>
                                                        </h3>
                                                    ))
                                                }
                                            </div>

                                            <hr />
                                            <div>
                                                <b>Loại món ăn :</b>
                                                <div className="badge-spacing" >
                                                    {
                                                        foodDetail.Type.map((t) => (
                                                            <span key={Math.random()} className="badge badge-success">{t.type.FOODTYPENAME}</span>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <p>{foodDetail.Description}</p>
                                            <hr />
                                            <div className="m-t-15 btn-showcase">
                                                <Link className="btn btn-primary" to={`/update-food/${foodSlug}`} title=""> <i className="fa fa-edit"></i>Cập nhật món ăn</Link>
                                                {/* <a className="btn btn-secondary" href="list-wish.html"> <i className="fa fa-heart me-1"></i>Ẩn món ăn</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default FoodDetailPage