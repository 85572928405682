import { useDispatch, useSelector } from "react-redux"
import RouteString from "../../Lib/RouteString"
import { useEffect, useState } from "react"
import { getAllOrdersAdmin } from '../../Store/Orders/slice'
import OrderList from "./OrderList"
import { Link } from "react-router-dom"
import React from "react"
const OrdersPage = () => {
    const dispatch = useDispatch()
    const { getAllOrdersAdminResult } = useSelector(state => state.orders)
    const [orderList, setOrderList] = useState([])
    const [orderListStore, setOrderListStore] = useState([])
    const [phoneParam, setPhoneParam] = useState('')
    const [monthParam, setmonthParam] = useState('Tất cả')
    const [yearParam, setYearParam] = useState('Tất cả')
    const [stateParam, setStateParam] = useState('Tất cả')
    useEffect(() => {
        dispatch(getAllOrdersAdmin())
        document.title='Đơn đặt/gọi món ăn - Quán ăn Cường Loan'
    }, [])
    useEffect(() => {
        let tempOrderList = orderListStore
        console.log(monthParam)
        if (phoneParam != '')
            tempOrderList = tempOrderList.filter(e => {
                return phoneParam === `0${JSON.parse(e).OrderCustomer.CUSTOMERPHONE}`
            }
            )
        if (monthParam != 'Tất cả')
            tempOrderList = tempOrderList.filter((e) => {
                const month = new Date(JSON.parse(e).OrderTime)
                return monthParam == month.getMonth() + 1
            }
            )
        if (yearParam != 'Tất cả')
            tempOrderList = tempOrderList.filter((e) => {
                const month = new Date(JSON.parse(e).OrderTime)
                return yearParam == month.getFullYear()
            }
            )
        if (stateParam != 'Tất cả')
            tempOrderList = tempOrderList.filter((e) => {
                return stateParam == JSON.parse(e).OrderState
            }
            )
        setOrderList(tempOrderList)
    }, [phoneParam, monthParam, yearParam, stateParam])
    useEffect(() => {
        if (getAllOrdersAdminResult !== undefined)
            if (getAllOrdersAdminResult.success) {
                setOrderList(getAllOrdersAdminResult.orders)
                setOrderListStore(getAllOrdersAdminResult.orders)
            }
    }, [getAllOrdersAdminResult])
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.ordersRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to={RouteString.ordersRoute.route} className="btn btn-outline-success" type="button">{RouteString.ordersRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="col-xxl-12">
                    <div className="email-right-aside">
                        <div className="card email-body email-list">
                            <div className="card-body custom-input">
                                <div className="row">
                                    <div className="col">
                                        <div className="mb-3 row">
                                            <label className="col-sm-3">SĐT Khách</label>
                                            <div className="col-sm-9">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Nhập số điện thoại của khách để tìm kiếm"
                                                    value={phoneParam}
                                                    onChange={(e) => setPhoneParam(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label className="col-sm-3">Tháng</label>
                                            <div className="col-sm-9">
                                                <select className="form-select" onChange={(e) => { setmonthParam(e.target.value) }} defaultValue={monthParam}>
                                                    <option value="Tất cả">Tất cả</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label className="col-sm-3">Năm</label>
                                            <div className="col-sm-9">
                                                <select className="form-select" onChange={(e) => { setYearParam(e.target.value) }} defaultValue={yearParam}>
                                                    <option value="Tất cả">Tất cả</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2023">2023</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label className="col-sm-3">Trạng thái</label>
                                            <div className="col-sm-9">
                                                <select className="form-select" onChange={(e) => { setStateParam(e.target.value) }} defaultValue={stateParam}>
                                                    <option value="Tất cả">Tất cả</option>
                                                    <option value="Chờ xác nhận">Chờ xác nhận</option>
                                                    <option value="Đang chuẩn bị">Đang chuẩn bị</option>
                                                    <option value="Đang giao">Đang giao</option>
                                                    <option value="Đã hoàn thành">Đã hoàn thành</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade show active">
                                    <div className="mail-body-wrapper">
                                        <OrderList
                                            props={{ orderList }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card-body">
                                <div className="taskadd">
                                    <div className="table-responsive">
                                        <OrderList
                                            props={{ orderList }}
                                        />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrdersPage