import RouteString from "../Lib/RouteString"
import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
import { Toaster } from "react-hot-toast"
import LoginPage from "./Auth/Login"
import HomePage from "./Home"
import AdminPage from "./Admin/Admin"
import FoodTypePage from "./FoodType/FoodType"
import FoodTypeDetailPage from "./FoodType/FoodTypeDetail"
import FoodPage from "./Food/Food"
import FoodDetailPage from "./Food/FoodDetail"
import FoodUpdatePage from "./Food/FoodUpdate"
import FoodAddPage from "./Food/FoodAdd"
import React from "react"
import OrdersPage from "./Orders/Orders"
import OrderDetailPage from "./Orders/OrderDetail"
import PartyCreatePage from "./Parties/PartyCreate"
import PartyUpdatePage from "./Parties/PartyUpdate"
import PartyPreparePage from "./Parties/PartyPrepare"
import PartyDonePage from "./Parties/PartyDone"
import PartyViewPage from "./Parties/PartyView"
import MenusPage from "./Menus/Menus"
import MenuCreatePage from "./Menus/MenuCreate"
import MenuUpdatePage from "./Menus/MenuUpdate"
const PageToDisplay = ({ props }) => {
    return (
        <>
            <Toaster />
            {
                (props.Route === RouteString.loginRoute.route) ?
                    < LoginPage />
                    :
                    <div className="page-wrapper horizontal-wrapper">
                        <Header />
                        <div className="page-body-wrapper">
                            {(() => {
                                switch (props.Route) {
                                    case RouteString.homeRoute.route:
                                        return <HomePage />
                                    case RouteString.adminRoute.route:
                                        return <AdminPage />
                                    case RouteString.foodTypeDetailRoute.route:
                                        return <FoodTypeDetailPage />
                                    case RouteString.foodTypeRoute.route:
                                        return <FoodTypePage />
                                    case RouteString.foodDetailRoute.route:
                                        return <FoodDetailPage />
                                    case RouteString.foodUpdateRoute.route:
                                        return <FoodUpdatePage />
                                    case RouteString.foodAddRoute.route:
                                        return <FoodAddPage />
                                    case RouteString.foodRoute.route:
                                        return <FoodPage />
                                    case RouteString.ordersRoute.route:
                                        return <OrdersPage />
                                    case RouteString.orderDetailRoute.route:
                                        return <OrderDetailPage />
                                    case RouteString.partyCreateRoute.route:
                                        return <PartyCreatePage />
                                    case RouteString.partyUpdateRoute.route:
                                        return <PartyUpdatePage />
                                    case RouteString.partyPrepareRoute.route:
                                        return <PartyPreparePage />
                                    case RouteString.partyDoneRoute.route:
                                        return <PartyDonePage />
                                    case RouteString.partyViewRoute.route:
                                        return <PartyViewPage />
                                    case RouteString.menuRoute.route:
                                        return <MenusPage />
                                    case RouteString.menuCreateRoute.route:
                                        return <MenuCreatePage />
                                    case RouteString.menuUpdateRoute.route:
                                        return <MenuUpdatePage />
                                    default:
                                        return null
                                }
                            })()}
                            <Footer />
                        </div>
                    </div>
            }


        </>
    )
}

export default PageToDisplay