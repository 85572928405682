import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useContext } from 'react';
import PageToDisplay from '../Page';
import RouteString from '../Lib/RouteString';
import { AuthContext } from '../Context/ContextConfig';
export const RouteController = () => {
    const authContext = useContext(AuthContext)
    return (
        <Router>
            <Routes>
                <Route
                    path={RouteString.loginRoute.route}
                    element={
                        <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                    }
                />

                <Route
                    path={RouteString.homeRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.homeRoute.route }}
                            />
                    }
                />

                <Route
                    path={RouteString.adminRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.adminRoute.route }}
                            />
                    }
                />

                <Route
                    path={RouteString.foodTypeDetailRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.foodTypeDetailRoute.route }}
                            />
                    }
                />

                <Route
                    path={RouteString.foodTypeRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.foodTypeRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.foodUpdateRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.foodUpdateRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.foodDetailRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.foodDetailRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.foodAddRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.foodAddRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.foodRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.foodRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.ordersRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.ordersRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.orderDetailRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.orderDetailRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.partyCreateRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.partyCreateRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.partyUpdateRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.partyUpdateRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.partyViewRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.partyViewRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.partyPrepareRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.partyPrepareRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.partyDoneRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.partyDoneRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.menuRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.menuRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.menuCreateRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.menuCreateRoute.route }}
                            />
                    }
                />
                <Route
                    path={RouteString.menuUpdateRoute.route}
                    element={
                        authContext.token === null ?
                            <PageToDisplay props={{ Route: RouteString.loginRoute.route }} />
                            :
                            <PageToDisplay props={{ Route: RouteString.menuUpdateRoute.route }}
                            />
                    }
                />
            </Routes>
        </Router>
    )
}