// import postSlice from './Post/slice'
// import courseSlice from './Course/slice'
import authSlice from './Auth/slice'
import adminSlice from './Admin/slice'
import foodTypeSlice from './FoodType/slice'
import foodSlice from './Food/slice'
import ordersSlice from './Orders/slice'
import partiesSlice from './Parties/slice'
import menusSlice from './Menus/slice'
import { combineReducers } from '@reduxjs/toolkit';

export const reducer = combineReducers({
    auth: authSlice,
    admin: adminSlice,
    foodType: foodTypeSlice,
    food: foodSlice,
    orders: ordersSlice,
    parties: partiesSlice,
    menus: menusSlice
});
