import { Link } from "react-router-dom"
import RouteString from '../Lib/RouteString'
import React from "react"
import Menu from "./Menu"
const Header = () => {
    return (
        <div className="page-header">
            <div className="header-wrapper row m-0">
                <div className="header-logo-wrapper col-auto p-0">
                    <div className="logo-wrapper">
                        <Link to={RouteString.homeRoute.route}><img className="img-fluid" src="../assets/images/logo/logo_website_QACL.png" alt="Quán ăn Cường Loan" /></Link></div>
                    <div className="toggle-sidebar"><i className="status_toggle middle sidebar-toggle" data-feather="align-center"></i></div>
                </div>
            </div>
            <Menu />
        </div>
    )
}

export default Header