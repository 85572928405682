/** @format */

import { createSlice } from "@reduxjs/toolkit";

const partiesSlice = createSlice({
    name: "parties",
    initialState: {},
    reducers: {
        createParty: (state, { payload }) => ({
            ...state
        }),
        createPartyResult: (state, { payload }) => ({
            ...state,
            createPartyResult: payload,
        }),
        getPartyDetail: (state, { payload }) => ({
            ...state
        }),
        getPartyDetailResult: (state, { payload }) => ({
            ...state,
            getPartyDetailResult: payload,
        }),
        updateParty: (state, { payload }) => ({
            ...state
        }),
        updatePartyResult: (state, { payload }) => ({
            ...state,
            updatePartyResult: payload,
        }),
        getAmLich: (state, { payload }) => ({
            ...state
        }),
        getAmLichResult: (state, { payload }) => ({
            ...state,
            getAmLichResult: payload,
        }),
        getParties: (state, { payload }) => ({
            ...state
        }),
        getPartiesResult: (state, { payload }) => ({
            ...state,
            getPartiesResult: payload,
        }),
    },
});

export const {
    createParty,
    createPartyResult,
    getPartyDetail,
    getPartyDetailResult,
    updateParty,
    updatePartyResult,
    getAmLich,
    getAmLichResult,
    getParties,
    getPartiesResult
} = partiesSlice.actions;
export default partiesSlice.reducer;
