/** @format */

import { createSlice } from "@reduxjs/toolkit";

const ordersSlice = createSlice({
    name: "foodtypes",
    initialState: {},
    reducers: {
        getAllOrdersAdmin: (state, { payload }) => ({
            ...state,
        }),
        getAllOrdersAdminResult: (state, { payload }) => ({
            ...state,
            getAllOrdersAdminResult: payload,
        }),
        getOrderDetailAdmin: (state, { payload }) => ({
            ...state,
        }),
        getOrderDetailAdminResult: (state, { payload }) => ({
            ...state,
            getOrderDetailAdminResult: payload,
        }),
        updateOrderStateAdmin: (state, { payload }) => ({
            ...state,
        }),
        updateOrderStateAdminResult: (state, { payload }) => ({
            ...state,
            updateOrderStateAdminResult: payload,
        }),
    },
});

export const {
    getAllOrdersAdmin,
    getAllOrdersAdminResult,
    getOrderDetailAdmin,
    getOrderDetailAdminResult,
    updateOrderStateAdmin,
    updateOrderStateAdminResult
} = ordersSlice.actions;
export default ordersSlice.reducer;
