import { useEffect, useState } from "react"
import { RouteController } from "./Route/RouteController"
import { AuthContext } from "./Context/ContextConfig"
import React from "react"

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"))
  const setLogin = (token) => {
    if (token != null)
      setToken(token)
  }
  useEffect(()=>{
    
  })
  return (
    <AuthContext.Provider
      value={
        {
          token: token || null,
          setLogin
        }
      }
    >
      <RouteController />
    </AuthContext.Provider>

  );
}

export default App;
