import { Link } from "react-router-dom"
import React from "react"
const FoodList = ({ props }) => {
    return (
        <div className="card-body pb-0">
            <div className="details-bookmark text-center">
                {
                    props.foodList.length > 0 ?
                        <div className="row">
                            {
                                props.foodList.map((e) => (
                                    <div key={Math.random()} className="col-xxl-3 col-md-4 col-ed-4">

                                        <div className="card card-with-border bookmark-card o-hidden">
                                            <div className="details-website"><img crossOrigin="anonymouse" className="img-fluid" src={e.Image[0].image.IMAGEURL} alt="" />
                                                <div className="desciption-data">
                                                    <div className="title-bookmark">
                                                        <Link key={Math.random()} to={e.Slug}>

                                                            <h6 className="title_0">{e.Name}</h6>
                                                            <p className="weburl_0">{e.Description}</p>
                                                        </Link>

                                                        <div className="hover-block">
                                                            <ul>
                                                                <li>
                                                                    <Link className="btn btn-xs btn-primary" to={`/update-food/${e.Slug}`}>
                                                                        <i className="fa fa-pencil-square-o"></i>
                                                                    </Link>
                                                                </li>
                                                                <li className="pull-right text-end">
                                                                    {
                                                                        e.isShow ?
                                                                            <button
                                                                                onClick={() => props.hideFoodHandle(e.Slug)}
                                                                                className="btn btn-xs btn-danger" type="button">
                                                                                <i className="fa fa-eye-slash"></i>
                                                                            </button>
                                                                            :
                                                                            <button
                                                                                onClick={() => props.showFoodHandle(e.Slug)}
                                                                                className="btn btn-xs btn-success" type="button">
                                                                                <i className="fa fa-eye"></i>
                                                                            </button>
                                                                    }

                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                        :

                        <div className="no-favourite"><span>Không tìm thấy món ăn nào</span></div>
                }

            </div>
        </div>
    )
}

export default FoodList