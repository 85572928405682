/** @format */

import { createSlice } from "@reduxjs/toolkit";

const menusSlice = createSlice({
    name: "parties",
    initialState: {},
    reducers: {
        createMenu: (state, { payload }) => ({
            ...state
        }),
        createMenuResult: (state, { payload }) => ({
            ...state,
            createMenuResult: payload,
        }),
        getMenus: (state, { payload }) => ({
            ...state
        }),
        getMenusResult: (state, { payload }) => ({
            ...state,
            getMenusResult: payload,
        }),
        getMenuDetail: (state, { payload }) => ({
            ...state
        }),
        getMenuDetailResult: (state, { payload }) => ({
            ...state,
            getMenuDetailResult: payload,
        }),
        updateMenu: (state, { payload }) => ({
            ...state
        }),
        updateMenuResult: (state, { payload }) => ({
            ...state,
            updateMenuResult: payload,
        }),
    },
});

export const {
    createMenu,
    createMenuResult,
    getMenus,
    getMenusResult,
    getMenuDetail,
    getMenuDetailResult,
    updateMenu,
    updateMenuResult
} = menusSlice.actions;
export default menusSlice.reducer;
