import { useNavigate } from "react-router-dom"
import React from "react"
import { DateTimeFromTimestamp, CurrentcyConvertVND } from '../../Lib/DateConvert'
import { textStateOfOrder } from "../../Lib/StateOfOrder"
const OrderList = ({ props }) => {
    const navigate = useNavigate()
    return (
        <ul>
            {
                (Array.isArray(props.orderList) && props.orderList.length > 0) ?
                    props.orderList.map((e) => {
                        let order = JSON.parse(e)
                        let orderDetail = JSON.parse(order.OrderDetail)
                        let orderTotal = orderDetail.reduce((total, item) => total + (item.Price * item.Count), 0)
                        let orderCustomer = order.OrderCustomer
                        return (
                            <li key={order.OrderID} className="inbox-data" onClick={() => navigate(`/order/${order.OrderID}`)}>
                                <div className="inbox-user">
                                    <img className="img-100 b-r-8" crossOrigin="anonymouse" src={orderDetail[0].Image[0]} alt="Món ăn" />
                                    <p>
                                        {`${orderCustomer.CUSTOMERGENDER ? 'Anh' : 'Chị'} ${orderCustomer.CUSTOMERNAME}`}
                                        <br />
                                        {`SĐT: 0${orderCustomer.CUSTOMERPHONE}`}
                                    </p>
                                </div>
                                <div className="inbox-message">
                                    <div className="email-data">
                                        <span>{`${orderDetail.length} món`}
                                            <span>{`Tổng cộng ${CurrentcyConvertVND(orderTotal)}`}</span>
                                        </span>
                                        {textStateOfOrder(order.OrderStateCode, order.OrderState)}
                                    </div>
                                    <div className="email-timing"><span>{DateTimeFromTimestamp(order.OrderTime)}</span></div>
                                    <div className="email-options">
                                        <i className="fa fa-print"></i>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                    :
                    <div className="alert alert-secondary dark" role="alert">
                        <p>Không tìm thấy đơn đặt món nào</p>
                    </div>
            }
        </ul>
    )
}

export default OrderList