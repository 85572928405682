import { useDispatch, useSelector } from "react-redux"
import RouteString from "../../Lib/RouteString"
import { useEffect, useState } from "react"
import { getPartyDetail, getAmLich } from '../../Store/Parties/slice'
import { Link, useParams } from "react-router-dom"
import React from "react"
import { CurrentcyConvertVND, TimestampToDDMMYYYY, getCurrentDateTime } from "../../Lib/DateConvert"
import toast from "react-hot-toast"
import FoodSelectedListView from "./FoodSelectedListView"
const PartyViewPage = () => {
    const dispatch = useDispatch()
    const { partyID } = useParams()
    const { updatePartyResult, getAmLichResult } = useSelector(state => state.parties)
    const { getPartyDetailResult } = useSelector(state => state.parties)
    const [customerPhone, setCustomerPhone] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [partyTime, setPartyTime] = useState('')
    const [partyCreateTime, setPartyCreateTime] = useState('')
    const [partyState, setPartyState] = useState('')
    const [partyPlace, setPartyPlace] = useState('')
    const [partyTables, setPartyTables] = useState(1)
    const [partyTableChair, setPartyTableChair] = useState(true)
    const [partyNote, setPartyNote] = useState('')
    const [foodSelected, setFoodSelected] = useState([])
    const [priceTotal, setPriceTotal] = useState(0)
    const [ngayDatAmLich, setNgayDatAmLich] = useState('')
    const [ngayDaiAmLich, setNgayDaiAmLich] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        dispatch(getPartyDetail(partyID))
        document.title = 'Xem đơn đặt tiệc - Quán ăn Cường Loan'
    }, [partyID])

    useEffect(() => {
        if (getPartyDetailResult !== undefined) {
            if (getPartyDetailResult.success) {
                setCustomerPhone(getPartyDetailResult.party.CustomerPhone)
                setCustomerName(getPartyDetailResult.party.CustomerName)
                setPartyTime(getPartyDetailResult.party.PartyStartTime)
                setPartyPlace(getPartyDetailResult.party.PartyPlace)
                setPartyTables(getPartyDetailResult.party.PartyTablesCount)
                setPartyTableChair(getPartyDetailResult.party.PartyTableAndChair)
                setPartyNote(getPartyDetailResult.party.PartyNote)
                setPartyCreateTime(getPartyDetailResult.party.PartyCreateTime)
                setFoodSelected(JSON.parse(getPartyDetailResult.party.PartyDetail))
                setPartyState(getPartyDetailResult.party.PartyState)
                setNgayDatAmLich(getPartyDetailResult.party.PartyCreateTimeAmLich)
                setPartyTime(getPartyDetailResult.party.PartyStartTime)
                setIsLoading(false)
            }
            else {
                window.location=`/party-preparing/1`
            }

        }
    }, [getPartyDetailResult])
    useEffect(() => {
        setNgayDaiAmLich('đang tính ngày')
        dispatch(getAmLich({
            "date": TimestampToDDMMYYYY(partyTime)
        }))
    }, [partyTime])
    useEffect(() => {
        if (getAmLichResult != undefined)
            setNgayDaiAmLich(getAmLichResult.date)
    }, [getAmLichResult])
    useEffect(() => {
        setPriceTotal(foodSelected.reduce((sum, e) => sum + parseInt(e.Price), 0))
    }, [foodSelected])
    const updatePartyHandle = () => {
        window.location = `/party-update/${partyID}`
    }

    useEffect(() => {
        if (updatePartyResult != undefined) {
            toast.remove()

            if (updatePartyResult.success)
                toast.success(updatePartyResult.message)
            else
                toast.error(updatePartyResult.message)
        }
    }, [updatePartyResult])

    const kiemTraXemCoMonTraiCayTrangMiengHayKhong = () => {
        let ok = false
        foodSelected.forEach(e => {
            if ((e.Name.toLowerCase().includes('trái cây') || e.Name.toLowerCase().includes('tráng miệng')) && foodSelected.length <= 4 && partyTableChair)
                ok = true
        })
        return ok
    }
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.partyViewRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to='/party-preparing/1' className="btn btn-outline-success" type="button">{RouteString.partyViewRoute.txt}</Link>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ?
                    <div className="loader-wrapper">
                        <div className="loader-index"> <span></span></div>
                        <svg>
                            <defs></defs>
                            <filter id="goo">
                                <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
                                <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"> </fecolormatrix>
                            </filter>
                        </svg>
                    </div>
                    :
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-wrapper border rounded-3">
                                            <div className="row g-3">
                                                <div className="col-md-12">
                                                    <h5>Ngày đặt: <span className="badge badge-success">{TimestampToDDMMYYYY(partyCreateTime)} Dương lịch</span> tức <span className="badge badge-info">{(ngayDatAmLich)} Âm lịch</span></h5>
                                                </div>
                                                <div className="col-md-12">
                                                    <h5>Trạng thái: {partyState == 'Đã đặt' ? <span className="badge badge-primary">{partyState}</span> : <span className="badge badge-success">{partyState}</span>}</h5>
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="form-label" htmlFor="customerPhonenumber">Số điện thoại khách hàng</h5>
                                                    <input className="form-control"
                                                        id="customerPhonenumber"
                                                        type="phone"
                                                        placeholder="Nhập số điện thoại khách hàng"
                                                        value={customerPhone}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="form-label" htmlFor="customerName">Tên khách hàng</h5>
                                                    <input className="form-control"
                                                        id="customerName"
                                                        type="phone"
                                                        placeholder="Nhập tên của khách hàng, VD: anh A, chị B"
                                                        value={customerName}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="form-label" htmlFor="partyTime">Ngày/giờ đãi tiệc, đã chọn <span className="badge badge-success">{`${new Date(partyTime).getHours() > 12 ? new Date(partyTime).getHours() - 12 : new Date(partyTime).getHours()} giờ ${new Date(partyTime).getMinutes()} ${new Date(partyTime).getHours() > 12 ? 'Chiều' : 'Sáng '}`}</span> ngày <span className="badge badge-success">{TimestampToDDMMYYYY(partyTime)} Dương lịch</span> tức <span className="badge badge-info">{ngayDaiAmLich} Âm lịch</span></h5>
                                                    <input className="form-control"
                                                        id="partyTime"
                                                        type="datetime-local"
                                                        value={partyTime}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="form-label" htmlFor="partyTables">Số bàn tiệc (tối thiểu là 1)</h5>
                                                    <input className="form-control"
                                                        id="partyTables"
                                                        type="number"
                                                        min={1}
                                                        max={100}
                                                        placeholder="Nhập số bàn tiệc"
                                                        value={partyTables}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="form-label" htmlFor="partyPlace">Địa điểm đãi tiệc</h5>
                                                    <textarea className="form-control"
                                                        id="partyPlace"
                                                        type="text"
                                                        placeholder="Nhập địa điểm đãi tiệc"
                                                        rows={3}
                                                        value={partyPlace}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <h5 className="form-label" htmlFor="partyNote">Ghi chú</h5>
                                                    <textarea className="form-control"
                                                        id="partyNote"
                                                        type="text"
                                                        placeholder="Nhập ghi chú về việc, ví dụ: giảm đường, tặng nước đá, tặng trái cây, tặng,......"
                                                        rows={3}
                                                        value={partyNote}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-check checkbox-checked">
                                                        <input disabled className="form-check-input" id="partyTableChair" type="checkbox" checked={partyTableChair} onChange={(e) => setPartyTableChair(!partyTableChair)} />
                                                        <label className="form-check-label" htmlFor="partyTableChair">Sử dụng bàn ghế (4 món trở lên miễn phí, ít hơn 4 món là 50.000/1 bộ)</label>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr className="border-bottom-primary">
                                                                <th scope="col">#</th>
                                                                <th>Món ăn đã chọn</th>
                                                                <th>Mức giá</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FoodSelectedListView
                                                                props={{
                                                                    foodSelected
                                                                }}
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="alert alert-success dark" role="alert">
                                                    <p>Tổng giá trị một mâm: {CurrentcyConvertVND(priceTotal + (((foodSelected.length <= 3 && partyTableChair) || kiemTraXemCoMonTraiCayTrangMiengHayKhong()) ? 50000 : 0))}</p>
                                                </div>
                                                <div className="alert alert-success dark" role="alert">
                                                    <p>Tổng giá trị tiệc {`${partyTables} mâm`}: {CurrentcyConvertVND(priceTotal * partyTables + (((foodSelected.length <= 3 && partyTableChair) || kiemTraXemCoMonTraiCayTrangMiengHayKhong()) ? 50000 * partyTables : 0))}</p>
                                                </div>
                                                {
                                                    new Date(getPartyDetailResult.party.PartyStartTime).valueOf() < new Date(getCurrentDateTime()).valueOf() ?
                                                        <>
                                                        </>
                                                        :
                                                        <div className="col-12">
                                                            <button className="btn btn-primary" onClick={() => updatePartyHandle()} >Cập nhật đơn</button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default PartyViewPage