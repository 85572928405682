import React from "react"
const AdminList = ({ props }) => {
    const adminSelectHandle = (index) => {
        props.setAdminActive(index)
    }
    return (
        <div className="col-xl-4 xl-50 col-md-5">
            <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                {
                    props.adminList.map((e, i) => (
                        <a
                            onClick={() => adminSelectHandle(i)}
                            key={e.Phone} className={`contact-tab nav-link ${i === props.adminActive ? `active show` : ``}`}>
                            <div className="media">
                                <img className="img-50 img-fluid m-r-20 rounded-circle update_img" crossOrigin="anonymouse" src={e.AVT} alt="" />
                                <div className="media-body">
                                    <h6> <span className="name">{e.Name}</span>
                                    </h6>
                                    <p className="phone">0{e.Phone}</p>
                                </div>
                            </div>
                        </a>
                    ))
                }
            </div>
        </div>
    )
}

export default AdminList