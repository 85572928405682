import React from "react"
import { CurrentcyConvertVND, DateTimeFromTimestamp } from '../../Lib/DateConvert'

const PartyList = ({ props }) => {
    const calculateDate = (date) => {
        console.log((date - new Date()) / (1000 * 3600 * 24))
        return (date - new Date()) / (1000 * 3600 * 24) < 1 && (date - new Date()) / (1000 * 3600 * 24) > 0 ?
            <span className="badge rounded-pill badge-secondary">Hôm nay</span>
            :
            parseInt((date - new Date()) / (1000 * 3600 * 24)) == 1 ?
                <span className="badge rounded-pill badge-warning text-dark">Ngày mai</span>
                :
                (date - new Date()) / (1000 * 3600 * 24) < 0 ?
                    <span className="badge rounded-pill badge-success">Đã qua</span>
                    :
                    <span className="badge rounded-pill badge-primary">Còn {parseInt((date - new Date()) / (1000 * 3600 * 24))} ngày</span>
    }

    const handlePartyView = (partyid) => {
        document.location = `/party/${partyid}`
    }
    return (
        (Array.isArray(props.partyList) && props.partyList.length > 0) ?
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th className="text-center">Ngày đãi</th>
                        <th className="text-center">Số món</th>
                        <th className="text-center">Số mâm</th>
                        <th className="text-center">Giá một mâm</th>
                        <th>Khách hàng</th>
                        <th>Địa chỉ đãi</th>
                    </tr>
                </thead>
                <tbody>
                    {props.partyList.map((e, i) => {
                        return (
                            <tr key={e.PARTYID} onClick={() => handlePartyView(`${e.PARTYID}`)}>
                                <th scope="row">{i + props.pagination.from}</th>
                                <td className="text-center">{DateTimeFromTimestamp(e.PARTYSTARTTIME)} {calculateDate(new Date(e.PARTYSTARTTIME))}</td>
                                <td className="text-center">{e.FOODCOUNT}</td>
                                <td className="text-center">{e.PARTYTABLESCOUNT}</td>
                                <td className="text-center">{CurrentcyConvertVND(e.PRICE)}</td>
                                <td>{e.CUSTOMERNAME} - {e.CUSTOMERPHONE}</td>
                                <td>{e.PARTYPLACE}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            :
            <div className="alert alert-secondary dark" role="alert">
                <p>Không tìm thấy đơn đặt tiệc nào</p>
            </div>
    )
}

export default PartyList