/** @format */

import instance from '.';

export const loginAPI = async (payload) => {
    return await instance.request({
        method: "POST",
        url: `/login`,
        data: payload
    });
};
