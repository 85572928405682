import React from "react"
import { CurrentcyConvertVND } from '../../Lib/DateConvert'
const FoodSelectedListView = ({ props }) => {
    return (
        (Array.isArray(props.foodSelected) && props.foodSelected.length > 0) ?
            props.foodSelected.map((e, i) => (
                <tr key={e.ID} className="border-bottom-primary">
                    <th scope="row">{i + 1}</th>
                    <td> <img className="img-30 me-2" crossOrigin="anonymouse" src={e.Image[0]} alt={e.Name} />{e.Name}</td>
                    <td>{CurrentcyConvertVND(e.Price)}</td>
                </tr>
            ))
            :
            <></>
    )
}

export default FoodSelectedListView