/** @format */

import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
    name: "admins",
    initialState: {},
    reducers: {
        getAllAdmins: (state, { payload }) => ({
            ...state,
        }),
        getAllAdminsResult: (state, { payload }) => ({
            ...state,
            getAllAdminsResult: payload,
        }),
        getAdmin: (state, { payload }) => ({
            ...state,
        }),
        getAdminResult: (state, { payload }) => ({
            ...state,
            getAdminResult: payload,
        }),
        revokeAdminAccount: (state, { payload }) => ({
            ...state,
        }),
        revokeAdminAccountResult: (state, { payload }) => ({
            ...state,
            revokeAdminAccountResult: payload,
        }),
        createAdminAccount: (state, { payload }) => ({
            ...state,
        }),
        createAdminAccountResult: (state, { payload }) => ({
            ...state,
            createAdminAccountResult: payload,
        }),
        updateAvatar: (state, { payload }) => ({
            ...state,
        }),
        updateAvatarResult: (state, { payload }) => ({
            ...state,
            updateAvatarResult: payload,
        }),
    },
});

export const {
    getAllAdmins,
    getAllAdminsResult,
    getAdmin,
    getAdminResult,
    revokeAdminAccount,
    revokeAdminAccountResult,
    createAdminAccount,
    createAdminAccountResult,
    updateAvatar,
    updateAvatarResult
} = adminSlice.actions;
export default adminSlice.reducer;
