/** @format */

import {
    createPartyAPI,
    getPartyDetailAPI,
    updatePartyAPI,
    getAmLichAPI,
    getPartiesAPI
} from '../../API/Parties';
import { call, put } from "redux-saga/effects";
import {
    createPartyResult,
    getPartyDetailResult,
    updatePartyResult,
    getAmLichResult,
    getPartiesResult
} from "./slice";

function* handleCreatePartyAPI({ payload }) {
    try {
        const response = yield call(createPartyAPI, payload);
        if (response.status === 200) {
            yield put(createPartyResult(response.data));
        }
    } catch (error) {
        yield put(createPartyResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetPartyDetailAPI({ payload }) {
    try {
        const response = yield call(getPartyDetailAPI, payload);
        if (response.status === 200) {
            yield put(getPartyDetailResult(response.data));
        }
    } catch (error) {
        yield put(getPartyDetailResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleUpdatePartyAPI({ payload }) {
    try {
        const response = yield call(updatePartyAPI, payload);
        if (response.status === 200) {
            yield put(updatePartyResult(response.data));
        }
    } catch (error) {
        yield put(updatePartyResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetAmLichAPI({ payload }) {
    try {
        const response = yield call(getAmLichAPI, payload);
        if (response.status === 200) {
            yield put(getAmLichResult(response.data));
        }
    } catch (error) {
        yield put(getAmLichResult({ success: false, code: Math.random(1000) }));
    }
}

function* handleGetPartiesAPI({ payload }) {
    try {
        const response = yield call(getPartiesAPI, payload);
        if (response.status === 200) {
            yield put(getPartiesResult(response.data));
        }
    } catch (error) {
        yield put(getPartiesResult({ success: false, code: Math.random(1000) }));
    }
}

export {
    handleCreatePartyAPI,
    handleGetPartyDetailAPI,
    handleUpdatePartyAPI,
    handleGetAmLichAPI,
    handleGetPartiesAPI
};
