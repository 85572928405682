/** @format */

import { createSlice } from "@reduxjs/toolkit";

const foodTypeSlice = createSlice({
    name: "foodtypes",
    initialState: {},
    reducers: {
        getAllFoodTypes: (state, { payload }) => ({
            ...state,
        }),
        getAllFoodTypesResult: (state, { payload }) => ({
            ...state,
            getAllFoodTypesResult: payload,
        }),
        getFoodTypeDetail: (state, { payload }) => ({
            ...state,
        }),
        getFoodTypeDetailResult: (state, { payload }) => ({
            ...state,
            getFoodTypeDetailResult: payload,
        }),
        updateFoodType: (state, { payload }) => ({
            ...state,
        }),
        updateFoodTypeResult: (state, { payload }) => ({
            ...state,
            updateFoodTypeResult: payload,
        }),
        updateFoodTypeImage: (state, { payload }) => ({
            ...state,
        }),
        updateFoodTypeImageResult: (state, { payload }) => ({
            ...state,
            updateFoodTypeImageResult: payload,
        }),
        addFoodType: (state, { payload }) => ({
            ...state,
        }),
        addFoodTypeResult: (state, { payload }) => ({
            ...state,
            addFoodTypeResult: payload,
        }),
    },
});

export const {
    getAllFoodTypes,
    getAllFoodTypesResult,
    getFoodTypeDetail,
    getFoodTypeDetailResult,
    updateFoodType,
    updateFoodTypeResult,
    updateFoodTypeImage,
    updateFoodTypeImageResult,
    addFoodType,
    addFoodTypeResult
} = foodTypeSlice.actions;
export default foodTypeSlice.reducer;
