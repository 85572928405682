import React from "react"
const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 footer-copyright text-center">
                        <p className="mb-0">Copyright © Quán ăn Cường Loan  </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer