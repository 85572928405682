import React from "react"
import { CurrentcyConvertVND } from '../../Lib/DateConvert'
const OrderDetailFoodList = ({ props }) => {
    let orderTotal = 0
    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Món ăn</th>
                    <th scope="col">Mức giá</th>
                    <th scope="col">Số lượng</th>
                    <th scope="col">Tổng</th>
                </tr>
            </thead>
            <tbody>
                {
                    (props.OrderDetail).map((e, i) => {
                        orderTotal += e.Price * e.Count
                        return (
                            <tr key={i + 1} >
                                <th scope="row">{i + 1}</th>
                                <td> <img className="img-30 me-2" crossOrigin="anonymouse" src={e.Image[0]} alt={e.Name} />{e.Name}</td>
                                <td>{CurrentcyConvertVND(e.Price)}</td>
                                <td>{e.Count}</td>
                                <td>{CurrentcyConvertVND(e.Price * e.Count)}</td>
                            </tr>
                        )
                    }
                    )
                }
            </tbody>
            <thead>
                <tr>
                    <th scope="col">Tổng cộng thanh toán</th>
                    <th scope="col">{CurrentcyConvertVND(orderTotal)}</th>
                </tr>
            </thead>
        </table >
    )
}

export default OrderDetailFoodList