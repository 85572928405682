import RouteString from "../Lib/RouteString"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getAllAdmins } from '../Store/Admin/slice'
import React from "react"
const HomePage = () => {
    const dispatch = useDispatch()
    const { getAllAdminsResult } = useSelector(state => state.admin)
    useEffect(() => {
        // dispatch(getAllAdmins())
        document.title='Trang chủ - Quán ăn Cường Loan'
    }, [])

    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.homeRoute.txt}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage