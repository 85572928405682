import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createAdminAccount, getAllAdmins } from '../../Store/Admin/slice'
import { vietnameseName, vietnamePhone } from '../../Lib/Regex'
import { toast } from "react-hot-toast"
import React from "react"
const AdminCreateModal = () => {
    const [adminPhone, setAdminPhone] = useState('')
    const [adminName, setAdminName] = useState('')
    const [adminAddress, setAdminAddress] = useState('')
    const [adminPassword, setAdminPassword] = useState('')
    const dispatch = useDispatch()
    const { createAdminAccountResult } = useSelector(state => state.admin)
    const createAdminAccountHandle = () => {
        toast.remove()
        if (adminPhone.match(vietnamePhone) === null)
            toast.error('Số điện thoại chưa phù hợp')
        if (!vietnameseName.test(adminName))
            toast.error('Họ tên chưa phù hợp')
        if (adminAddress === '')
            toast.error('Địa chỉ chưa phù hợp')
        if (adminPassword === '')
            toast.error('Mật khẩu chưa phù hợp')
        if (adminPhone.match(vietnamePhone) !== null && vietnameseName.test(adminName) && adminAddress !== '' && adminPassword !== '')
            dispatch(createAdminAccount({
                adminphone: adminPhone,
                adminname: adminName,
                adminaddress: adminAddress,
                adminpassword: adminPassword,
            }))
    }

    useEffect(() => {
        if (createAdminAccountResult !== undefined)
            if (createAdminAccountResult.success) {
                toast.success('Tạo tài khoản quản trị thành công')
                dispatch(getAllAdmins())
                setAdminPhone('')
                setAdminName('')
                setAdminAddress('')
                setAdminPassword('')
            } else toast.error('Số điện thoại đã được sử dụng, vui lòng chọn số khác')
    }, [createAdminAccountResult])
    return (
        <div className="modal fade" id="adminCreateModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label" for="adminCreateModalName">Họ tên:</label>
                                    <input className="form-control"
                                        value={adminName}
                                        onChange={(e) => setAdminName(e.target.value)}
                                        id="adminCreateModalName"
                                        type="text" placeholder="Nhập họ tên" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label" for="adminCreateModalPhone">Số điện thoại:</label>
                                    <input className="form-control"
                                        id="adminCreateModalPhone"
                                        value={adminPhone}
                                        onChange={(e) => setAdminPhone(e.target.value)}
                                        type="text" placeholder="Nhập số điện thoại" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label" for="adminCreateModalAddress">Địa chỉ:</label>
                                    <input className="form-control"
                                        value={adminAddress}
                                        onChange={(e) => setAdminAddress(e.target.value)}
                                        id="adminCreateModalAddress"
                                        type="text" placeholder="Nhập địa chỉ" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label" for="adminCreateModalPassword">Mật khẩu khởi tạo:</label>
                                    <input className="form-control"
                                        value={adminPassword}
                                        onChange={(e) => setAdminPassword(e.target.value)}
                                        id="adminCreateModalPassword"
                                        type="text" placeholder="Nhập mật khẩu khởi tạo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary"
                            onClick={() => createAdminAccountHandle()}
                            type="button">Tạo tài khoản</button>
                        <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Đóng</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminCreateModal