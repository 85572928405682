import { useDispatch, useSelector } from "react-redux"
import RouteString from "../../Lib/RouteString"
import { useEffect, useState } from "react"
import { getMenus } from '../../Store/Menus/slice'
import { Link } from "react-router-dom"
import { CurrentcyConvertVND } from "../../Lib/DateConvert"
import React from "react"
const MenusPage = () => {
    const dispatch = useDispatch()
    const { getMenusResult } = useSelector(state => state.menus)
    const [menuList, setMenuList] = useState([])
    const [weddingMenuList, setWeddingMenuList] = useState([])
    useEffect(() => {
        dispatch(getMenus())
        document.title = 'Menu mẫu - Quán ăn Cường Loan'
    }, [])
    useEffect(() => {
        if (getMenusResult != undefined && getMenusResult.success) {
            setMenuList(getMenusResult.menus)
            setWeddingMenuList(getMenusResult.weddingMenus)
        }
    }, [getMenusResult])
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{RouteString.menuRoute.txt}</h3>
                        </div>
                        <div className="col-6 breadcrumb">
                            <Link to={RouteString.menuRoute.route} className="btn btn-outline-success" type="button">{RouteString.menuRoute.txt}</Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title mb-0">Thêm menu mới</h4>
                        </div>
                        <div class="card-body common-flex">
                            <Link class="btn btn-primary" type="button" to={RouteString.menuCreateRoute.route}>{RouteString.menuCreateRoute.txt}</Link>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title mb-0">Menu tiệc tận nhà</h4>
                            <div className="card-options"><a className="card-options-collapse" href="#" data-bs-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#" data-bs-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                        </div>
                        <div className="table-responsive add-project">
                            <table className="table card-table table-vcenter text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Tên menu</th>
                                        <th className="text-center">Số món ăn</th>
                                        <th className="text-center">Tổng giá</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (Array.isArray(menuList) && menuList.length > 0) ?
                                            menuList.map((e) => (
                                                <tr key={e.MENUID}>
                                                    <td><Link className="text-inherit" to={`/update-menu/${e.MENUID}`}>{e.MENUNAME}</Link></td>
                                                    <td className="text-center">{e.FOODS}</td>
                                                    <td className="text-center">{CurrentcyConvertVND(e.MENUPRICE)}</td>
                                                    <td className="text-end">
                                                        <Link className="btn btn-primary btn-sm" to={`/update-menu/${e.MENUID}`}>
                                                            <i className="fa fa-pencil"></i> Cập nhật
                                                        </Link>
                                                        <a className="btn btn-danger btn-sm" href="javascript:void(0)">
                                                            <i className="fa fa-trash"></i> Xóa
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <></>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title mb-0">Menu tiệc cưới</h4>
                            <div className="card-options"><a className="card-options-collapse" href="#" data-bs-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#" data-bs-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                        </div>
                        <div className="table-responsive add-project">
                            <table className="table card-table table-vcenter text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Tên menu</th>
                                        <th className="text-center">Số món ăn</th>
                                        <th className="text-center">Tổng giá</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (Array.isArray(weddingMenuList) && weddingMenuList.length > 0) ?
                                            weddingMenuList.map((e) => (
                                                <tr key={e.MENUID}>
                                                    <td><Link className="text-inherit" to={`/update-menu/${e.MENUID}`}>{e.MENUNAME}</Link></td>
                                                    <td className="text-center">{e.FOODS}</td>
                                                    <td className="text-center">{CurrentcyConvertVND(e.MENUPRICE)}</td>
                                                    <td className="text-end">
                                                        <Link className="btn btn-primary btn-sm" to={`/update-menu/${e.MENUID}`}>
                                                            <i className="fa fa-pencil"></i> Cập nhật
                                                        </Link>
                                                        <a className="btn btn-danger btn-sm" href="javascript:void(0)">
                                                            <i className="fa fa-trash"></i> Xóa
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <></>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenusPage