import { Link, parsePath, useNavigate, useParams } from "react-router-dom"
import RouteString from "../../Lib/RouteString"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    getFoodTypeDetail,
    updateFoodType
} from '../../Store/FoodType/slice'
import { vietnameseName } from '../../Lib/Regex'
import { toast } from "react-hot-toast"
import axios from "axios"
import { APIHost } from "../../Lib/Constant"
import { AuthContext } from "../../Context/ContextConfig"
import { MoonLoader } from 'react-spinners';
import React from "react"
const FoodTypeDetailPage = () => {
    const { foodTypeSlug } = useParams()
    const [foodTypeDetail, setFoodTypeDetail] = useState(null)
    const [foodTypeNameNew, setFoodTypeNameNew] = useState('')
    const [foodTypeImageNew, setFoodTypeImageNew] = useState(null)
    const [imageUploading, setImageUploading] = useState(false)
    const dispatch = useDispatch()
    const { getFoodTypeDetailResult, updateFoodTypeResult } = useSelector(state => state.foodType)
    const navigate = useNavigate()
    const authContext = useContext(AuthContext)
    useEffect(() => {
        if (foodTypeSlug !== undefined)
            dispatch(getFoodTypeDetail(foodTypeSlug))
        document.title = 'Chi tiết loại món ăn - Quán ăn Cường Loan'
    }, [foodTypeSlug])
    useEffect(() => {
        if (getFoodTypeDetailResult !== undefined) {
            if (getFoodTypeDetailResult.success !== undefined) {
                navigate(`/food-types`)
            } else {
                setFoodTypeDetail(getFoodTypeDetailResult.data)
                setFoodTypeNameNew(getFoodTypeDetailResult.data.Name)
            }
        }
    }, [getFoodTypeDetailResult])
    const updateFoodTypeHandle = () => {
        if (vietnameseName.test(foodTypeNameNew))
            toast.error('Tên loại món ăn chưa phù hợp')
        else
            dispatch(updateFoodType({
                foodtypeslug: foodTypeSlug,
                foodtypename: foodTypeNameNew
            }))
    }
    useEffect(() => {
        if (updateFoodTypeResult !== undefined) {
            if (updateFoodTypeResult.success) {
                navigate(`/food-types/${updateFoodTypeResult.foodtype.Slug}`)
            }
        }
    }, [updateFoodTypeResult])

    useEffect(() => {
        if (foodTypeImageNew !== null) {
            const formData = new FormData();
            formData.append('image', foodTypeImageNew);
            try {
                setImageUploading(true)
                axios({
                    url: `${APIHost}/edit-food-type-image/${foodTypeSlug}`,
                    method: 'post',
                    headers: { 'Authorization': `Bearer ${authContext.token}` },
                    data: formData
                }).then((result) => {
                    toast.remove()
                    setImageUploading(false)
                    if (result.data.success) {
                        toast.success(result.data.message)
                        // dispatch(getFoodTypeDetail(foodTypeSlug))
                        setFoodTypeDetail(result.data.foodtype)
                    }
                    else
                        toast.error(result.data.message.image)
                })
                setFoodTypeImageNew(null)
            } catch (error) {
                toast.error(`Vui lòng thử lại sau`)
            }
        }
    }, [foodTypeImageNew])
    return (
        foodTypeDetail !== null ?
            <div className="page-body">
                <div className="container-fluid">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-6">
                                <h3>{RouteString.foodTypeRoute.txt}: {foodTypeDetail.Name}</h3>
                            </div>
                            <div className="col-6 breadcrumb">
                                <Link to={RouteString.foodTypeRoute.route} class="btn btn-outline-success" type="button">{RouteString.foodTypeRoute.txt}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title mb-0">Thông tin loại món ăn</h4>
                                        <div className="card-options">
                                            <a className="card-options-collapse" href="#" data-bs-toggle="card-collapse">
                                                <i className="fe fe-chevron-up"></i></a>
                                            <a className="card-options-remove" href="#" data-bs-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                    </div>
                                    <div className="card-body">

                                        <div className="row mb-2">
                                            <div className="profile-title">
                                                <div className="media">
                                                    {
                                                        imageUploading ?
                                                            <MoonLoader
                                                                color="#36d7b7"
                                                            />
                                                            :
                                                            <>
                                                                <img crossOrigin="anonymouse" className="img-100 rounded-circle" alt="" src={foodTypeDetail.Image} />
                                                                <input
                                                                    className="updateimg"
                                                                    type="file" name="img"
                                                                    onChange={(e) => setFoodTypeImageNew(e.target.files[0])}
                                                                />
                                                            </>
                                                    }
                                                    <div className="media-body">
                                                        <h5 className="mb-1"><span className="badge badge-secondary">{foodTypeDetail.Name}</span></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Cập nhật loại món ăn</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="#" data-bs-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#" data-bs-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-12">
                                                <label className="form-label">Cập nhật tên loại món ăn</label>
                                                <input
                                                    value={foodTypeNameNew}
                                                    onChange={(e) => setFoodTypeNameNew(e.target.value)}
                                                    className="form-control"
                                                    type="text" placeholder="Nhập tên mới cho loại món ăn" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <button
                                        onClick={() => updateFoodTypeHandle()}
                                        className="btn btn-primary">Cập nhật</button>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title mb-0">Các món ăn thuộc loại {foodTypeDetail.Name}</h4>
                                        <div className="card-options"><a className="card-options-collapse" href="#" data-bs-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#" data-bs-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                    </div>
                                    <div className="table-responsive add-project">
                                        <table className="table card-table table-vcenter text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Tên món ăn</th>
                                                    <th>Loại món ăn</th>
                                                    <th>Mức giá</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    foodTypeDetail.Foods.map((e) => (
                                                        <tr key={Math.random()}>
                                                            <td>
                                                                <Link className="text-inherit" to="#" key={Math.random()}>
                                                                    <img crossOrigin="anonymouse" className="img-30 me-2" src={e.Image[0].image.IMAGEURL} />
                                                                    {e.Name}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="badge-spacing" key={Math.random()}>
                                                                    {
                                                                        e.Type.map((t) => (
                                                                            <span key={Math.random()} className="badge badge-success">{t.type.FOODTYPENAME}</span>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    e.Variant.map((v) => (
                                                                        <div key={Math.random()}>
                                                                            <span className="badge badge-warning">{`${v.VARIANT} - 
                                                                                ${v.RATION} người - 
                                                                                ${v.PRICEVALUE.toLocaleString('vi-VN', {
                                                                                style: 'currency',
                                                                                currency: 'VND',
                                                                            })}`}
                                                                            </span>
                                                                            <br />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <></>
    )
}

export default FoodTypeDetailPage